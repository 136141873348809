import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles/home.css";

const ArticleSquares = ({ article }) => {
  const navigate = useNavigate();

  const handleReadClick = (articleId) => {
    // Use navigate to navigate to the article
    console.log("Handle function", articleId);
    if (articleId === 10) {
      navigate("/crackingTheCode");
    } else if (articleId === 9) {
      navigate("/marchingToSuccess");
    } else if (articleId === 8) {
      navigate("/nextSaturday");
    } else if (articleId === 12) {
      navigate("/marchingContests");
    } else if (articleId === 11) {
      navigate("/aSymphonyofSupport");
    } else if (articleId === 1) {
      navigate("/marchingBandWhereExcellenceAndSuccessUnite");
    } else if (articleId === 3) {
      navigate("/harmonizingHearts");
    }
    else if (articleId === 7) {
      navigate("/MarchingBandAFusion");
    }
    else if (articleId === 6) {
      navigate("/winnersOfThePast");
    } else if (articleId === 4) {
      navigate("/marchingBandsUnveilingTheRhythmOfStyle");
    } else if (articleId === 2) {
      navigate("/symphonyOfAspirations");
    } else if (articleId === 14) {
      navigate("/evolutionOfMarchingBandUniforms");
    } else if (articleId === 13) {
      navigate("/marchingBandJokes");
    } else if (articleId === 5) {
      navigate("/historyOfMarchingBands");
    } else if (articleId === 15) {
      navigate("/recipes");
    }
  };



  return (
    <div key={article.id} className="article-square"
      onClick={() => handleReadClick(article.id)}
    >
      <div
        className="article-square-image"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}${article.image})`,
        }}

      >

      </div>
      <div className="article-square-title">
        <div className="article-title-upper">
          <h4>{article.title}</h4>
          <div className="article-square-line"></div>
        </div>
        <div className="article-title-lower">
          <p className="sub-title">{article.subtitle}</p>
          <div className="read-article-btn">
            <svg className='read-arrow' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.53 162.9">
              <path
                className='readit'
                d="M326.1,165.87a7.75,7.75,0,0,1,0,12.12l-52,41.61-38.69,30.94a12.86,12.86,0,0,1-20.9-10h0a12.88,12.88,0,0,1,4.83-10.05l13.57-10.85,59.6-47.67-59.6-47.65-13.57-10.85a12.89,12.89,0,0,1-4.83-10.05h0a12.86,12.86,0,0,1,20.9-10l38.69,31Z"
                transform="translate(-214.49 -90.49)"
              />
            </svg>
          </div>
        </div>


      </div>
    </div>
  );
};

export default ArticleSquares;
