const templates = [
    {
        id: 1,
        image: 'images/flag-1.png'
    },
    {
        id: 2,
        image: 'images/flag-2.png'
    },
    {
        id: 3,
        image: 'images/flag-3.png'
    },
    {
        id: 4,
        image: 'images/flag-4.png'
    },
    {
        id: 5,
        image: 'images/flag-5.png'
    },
    {
        id: 6,
        image: 'images/flag-6.png'
    },
    {
        id: 7,
        image: 'images/flag-7.png'
    },
    {
        id: 8,
        image: 'images/flag-8.png'
    },
    {
        id: 9,
        image: 'images/flag-9.png'
    },
    {
        id: 10,
        image: 'images/flag-10.png'
    },
    {
        id: 11,
        image: 'images/flag-11.png'
    },
    {
        id: 12,
        image: 'images/flag-12.png'
    },
    {
        id: 13,

        image: 'images/hat-2.png'
    },
    {
        id: 14,
        image: 'images/hat-1.png'
    },
]

export default templates