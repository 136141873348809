const articles = [
    {
        id: 1,
        title: 'Who Are These Band Members?',
        image: 'images/who-are-these.jpg',
        subtitle: 'Marching Band: Where Excellence and Success Unite'
    },
    {
        id: 2,
        title: 'Symphony of Aspirations',
        image: 'images/soa-home.jpg',
        subtitle: 'A Journey Through Music, Camaraderie, and Achieving Excellence.'
    },
    {
        id: 3,
        title: 'Harmonizing Hearts',
        image: 'images/fan-etiquette-small.jpg',
        subtitle: 'The Inspiriting Role of the Audience in a Marching Band Contest'
    },
    {
        id: 4,
        title: "Each Band Has Their Own Style",
        image: 'images/got-style.jpeg',
        subtitle: "Marching Band: Unveiling the Rhythm of Style"
    },
    {
        id: 5,
        title: "History of Marching Bands",
        image: 'images/homb-home.jpeg',
        subtitle: 'Cadence of Glory: A Tale of American Marching Bands'
    },
    {
        id: 6,
        title: "Celebrities in Marching Band",
        image: 'images/lizzo-home-pic.jpeg',
        subtitle: 'The Impact of Marching Bands on Icons of Success'
    },
    {
        id: 7,
        title: "Is Marching Band a Sport",
        image: 'images/is-it-a-sport.jpg',
        subtitle: 'Marching Band: A Fusion of Sport and Performance Art'
    },
    {
        id: 8,
        title: "The Saturday After",
        image: 'images/next-saturday-small.jpeg',
        subtitle: "Transitioning from Marching Band Season: Supporting Your Child's Journey"
    },
    {
        id: 9,
        title: "Marching to Success",
        image: 'images/marching-to-success.jpeg',
        subtitle: 'How the Band Journey Can Lead to College Scholarships'
    },
    {
        id: 10,
        title: "Scoring System of Marching Band Contest",
        image: '/images/cracking-code.jpeg',
        subtitle: "Cracking the Code: How Marching Bands Win Big"
    },

    {
        id: 11,
        title: "Being a Marching Band Parent",
        image: 'images/parents-small.jpeg',
        subtitle: "A Symphony of Support: What it Takes to Be a Marching Band Parent"
    },

    {
        id: 12,
        title: "Marching Contests",
        image: 'images/marching-contests-small.jpeg',
        subtitle: 'Where More than Music Takes Flight'
    },
    {
        id: 13,
        title: "Marching Band Jokes",
        image: 'images/band-jokes-cover.jpg',
        subtitle: 'Marching Band Can be Funny too'
    },
    {
        id: 14,
        title: "Evolution of Marching Band Uniforms",
        image: 'images/evolution.jpeg',
        subtitle: 'A Storied Tale'
    },

    {
        id: 15,
        title: "Tailgating Recipes",
        image: 'images/tail-gate-home.jpeg',
        subtitle: 'The Art of Tailgating'
    },





]

export default articles
