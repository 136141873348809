import React from 'react';
import GameHomepage from '../GameHomepage.js';
import Ad from '../Ad.js';

const Game = () => {
    return (
        <div>
            <GameHomepage />
            <Ad />
        </div>
    )
}

export default Game
