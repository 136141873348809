import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const DesktopHeader = () => {

    const location = useLocation();

    return (
        <div id='desktop-header'>
            <img src={process.env.PUBLIC_URL + '/images/logo-pink.png'} width='50px'></img>
            <nav className='desktop-nav'>
                <ul className='nav-links'>
                    <li>
                        <Link style={{ textDecoration: 'none' }} to='/'>
                            <div className={`game-controller-btn ${location.pathname === '/' ? 'active-tab' : ''}`}>
                                <img className="tab-icon" src={process.env.PUBLIC_URL + '/images/home-icon.png'} ></img>
                                <p>Home</p>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link style={{ textDecoration: 'none' }} to='/about'>
                            <div className={`game-controller-btn ${location.pathname === '/about' ? 'active-tab' : ''}`}>
                                <img className="tab-icon" src={process.env.PUBLIC_URL + '/images/about-icon.png'} ></img>
                                <p>About</p>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link style={{ textDecoration: 'none' }} to='/articlespage'>
                            <div className={`game-controller-btn ${location.pathname === '/articlespage' ? 'active-tab' : ''}`}>
                                <img className="tab-icon" src={process.env.PUBLIC_URL + '/images/article-icon.png'} ></img>
                                <p>Articles</p>
                            </div>
                        </Link>

                    </li>
                    <li>
                        <Link style={{ textDecoration: 'none' }} to='/games'>
                            <div className={`game-controller-btn ${location.pathname === '/games' ? 'active-tab' : ''}`}>
                                <img className="tab-icon" src={process.env.PUBLIC_URL + '/images/controller-purple.png'} ></img>
                                <p>Games</p>
                            </div>
                        </Link>

                    </li>

                    <li >
                        <Link style={{ textDecoration: 'none' }} to='/activitiespage'>
                            <div className={`game-controller-btn ${location.pathname === '/activitiespage' ? 'active-tab' : ''}`}>
                                <img className="tab-icon" src={process.env.PUBLIC_URL + '/images/activities-icon.png'} ></img>
                                <p>Activities</p>
                            </div>
                        </Link>

                    </li>

                </ul>
            </nav>

        </div>
    )
}

export default DesktopHeader
