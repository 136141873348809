import React, { useState } from 'react'

const Flag8 = ({ selectedColor, onPathClick, selectedStroke }) => {

    const [isClicked, setIsClicked] = useState(new Array(30).fill(false));

    const handleClick = (event, index) => {
        onPathClick(event);


        setIsClicked((prevIsClicked) =>
            prevIsClicked.map((clicked, i) => (i === index ? !clicked : clicked))
        );
    };
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 561.55 441.48"
            className='svg-temp'
        >
            <defs>
                <style>
                    {
                        `
                        .b{stroke:${selectedStroke};stroke-miterlimit:10;stroke-width:3px;}
                    `
                    }

                </style>
            </defs>
            <path
                className="a"
                d="M150.77,406.51H166c73.51.05,147.06-1.19,220.54.63,48.78,1.21,97.79,4.4,146.09,11.05,57.37,7.9,112,25.74,158.43,62.66,6.44,5.11,12.77,10.37,20.11,16.35.5-3.66.84-5,.84-6.32q.09-177.55.34-355.1c0-9.15-4.24-13.08-11.66-15.7-26.8-9.46-53.26-20.06-80.47-28.16A815.41,815.41,0,0,0,407.72,58.51c-56.61-1.47-113.24-2-169.86-2.63-28.84-.33-57.68-.06-87.09-.06Z" transform="translate(-150.77 -55.71)"


            />
            <path
                className="a" d="M186.83,71.69c60.77,0,121.07.1,181.37,0,75.5-.16,150.18,7,223.07,27.18,33.05,9.15,65.22,21.5,97.84,32.23,7.18,2.36,11.29,5.9,11.26,14.4Q700,298.61,700,451.76c0,4.47-.82,8.93-1.32,14-16.59-9.31-32-19.09-48.34-26.94-58-27.87-120.39-39.88-183.88-42.59-84.24-3.6-168.64-3.27-253-4.6-8.52-.13-17,0-26.62,0Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[0] ? selectedColor : 'white'}
            />
            <path className="a" d="M174.71,392.6h-14V71.8l14-.91Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[1] ? selectedColor : 'white'}
            />
            <path className="b" d="M698.11,383.23c-1.58,3.14-3.4,3.64-6.68,1.66-3.92-2.36-6.76-5.67-9.42-9.08A101,101,0,0,0,669.63,362a67.06,67.06,0,0,0-37.84-16.66,94.84,94.84,0,0,0-34,2.79,130.5,130.5,0,0,0-26.19,9.71,2.39,2.39,0,0,1-1.15.32c.28-.2.54-.42.83-.6,12.3-7.49,25.23-13.9,39.48-18a93.5,93.5,0,0,1,29.47-3.93c30.77,1.13,50.7,21,56.72,39,.42,1.23.76,2.49,1.14,3.73Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[2] ? selectedColor : 'white'}
            />
            <path className="b" d="M361.15,71.66c-.27,3-.68,5.91-.77,8.87-.4,12.18,2.69,23.79,7.51,35.08,6.94,16.26,17.29,30.73,29.38,44.27,9.28,10.39,19.71,19.83,30.39,29.08,23.38,20.23,45.4,41.53,63.41,65.89,11.66,15.77,21.07,32.49,26.93,50.72a137.07,137.07,0,0,1,6,29.88A151.5,151.5,0,0,1,523,370c-.35-3.77-.58-7.57-1.06-11.34a98.78,98.78,0,0,0-7.8-27.13c-7.72-17.72-19.35-33.22-33.81-47.08-17.89-17.14-38.87-30.66-61.67-42-14.54-7.26-28-15.84-39.6-26.56-17-15.72-28.26-34.19-32.86-55.77a110.29,110.29,0,0,1-1.81-33.94,144.2,144.2,0,0,1,16.12-53.33C360.68,72.37,360.94,72,361.15,71.66Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[3] ? selectedColor : 'white'}
            />
            <path className="b" d="M218.59,246.56a83,83,0,0,0,17.61,1c12.15-.44,24.05-2.39,35.88-4.76a275.33,275.33,0,0,1,35.1-5,75.75,75.75,0,0,1,18.54.46l-6.22.89a124.55,124.55,0,0,0-49.28,17.63,55.54,55.54,0,0,1-15.7,6.88c-7.88,2-15.71,1.27-23.46-.62a112,112,0,0,1-31-13.27c-5.45-3.27-10.59-6.93-15.87-10.42-.52-.34-1-.75-1.72-1.31,2,.11,3.71.3,5.41.26a29.84,29.84,0,0,0,20.29-8.49,59.84,59.84,0,0,0,7-8c3.63-4.81,7.41-9.47,12.45-13.2,9.67-7.14,20.36-8,31.83-4.12a78.76,78.76,0,0,1,19.07,10.12c12.25,8.42,25.17,15.83,39.6,21,.23.08.44.21.78.37-3.42-.38-6.69-.8-10-1.1a121.45,121.45,0,0,0-19.82-.05c-10.82.75-21,3.89-31.32,6.48a175.94,175.94,0,0,1-31.94,5.24C223.44,246.69,221,246.56,218.59,246.56Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[4] ? selectedColor : 'white'}
            />
            <path className="b" d="M504.79,88.57a43.47,43.47,0,0,0-6.91,14.78c-2.81,11.11-.89,21.66,4.72,31.77,3.59,6.46,7,13,9,20a41.58,41.58,0,0,1,.64,19.73c-2.39,12-5.06,24-7.53,36-1.52,7.4-2.89,14.83-2.87,22.4,0,7,1.92,13.64,4.66,20.13a4.46,4.46,0,0,1,.5,2c-1.85-3.27-3.76-6.51-5.54-9.8a262.32,262.32,0,0,1-22.76-55.29c-3.57-13-5.83-26.06-5.75-39.44.07-12.7,2.26-25,8.26-36.67A66.05,66.05,0,0,1,504.79,88.57Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[5] ? selectedColor : 'white'}
            />
            <path className="b" d="M388.34,368.53c-3.33-.36-6.69-.58-10-1.1-13.28-2.12-25.29-6.8-35.59-14.66-10.09-7.69-17.38-17-20.24-28.79-3.51-14.46,1.06-26.75,13-36.83,8.4-7.08,18.64-10.95,29.78-13.07a110.86,110.86,0,0,1,36-.82l-3.09.47c-6.59,1-12.85,2.76-18.16,6.57a20.48,20.48,0,0,0-9,15.51c-.31,4.11.13,8.27.23,12.41s0,8.39-1.46,12.45a17.05,17.05,0,0,1-5.54,7.62,45,45,0,0,0-5.87,5.06,21.9,21.9,0,0,0-3.36,5.83c-1.05,2.48-.2,4.9,1.15,7.14,2.57,4.28,6.56,7.41,10.78,10.31a126.06,126.06,0,0,0,20.72,11.16c.22.09.43.2.65.31Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[6] ? selectedColor : 'white'}
            />
            <path className="b" d="M591.1,294.36c1.21-1.57,2.27-3.14,3.55-4.57a20.37,20.37,0,0,1,10.36-6c2.41-.62,4.81-1.33,7.15-2.14a23.69,23.69,0,0,0,11.74-9.21,15.87,15.87,0,0,1,7.38-5.84c.44-.16.9-.3,1.73-.58-7,6.05-8.1,13.41-7.25,21.12.77,7,2.14,14,3,21.06a32.29,32.29,0,0,1-.43,12.38,12.33,12.33,0,0,1-3.84,6.33c23.19-1,42.55,6.54,59.43,19.9a4.6,4.6,0,0,1-.52-.19c-.17-.1-.31-.23-.47-.34a91.63,91.63,0,0,0-33.34-14.82c-15.89-3.37-31.36-1.88-46.47,3.35a99.15,99.15,0,0,0-26.94,14.32,85.22,85.22,0,0,1-7.93,5.18c1.75-3.28,3.48-6.56,5.24-9.84a114.69,114.69,0,0,0,12.61-36.51c3.48-22.21-.41-43.45-11-63.74A108.25,108.25,0,0,0,564,227a1.66,1.66,0,0,1-.42-.91C580.88,246.22,590.16,268.92,591.1,294.36Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[7] ? selectedColor : 'white'}
            />
            <path className="b" d="M431.86,286.33c-1.36,2.86-.51,5.3.38,7.68,2.13,5.7,5.63,10.77,9.27,15.76,3.5,4.79,7.29,9.43,10.51,14.37A32.54,32.54,0,0,1,457.71,343c-.24,9.65-4.89,17.47-13.13,23.55-4.26,3.14-9.56,4.14-14.92,4.14-4.61,0-9.23-.65-13.84-1a4.28,4.28,0,0,1-.94-.33c11.85-6.63,19.91-15.55,22.55-27.94-6.31,11.53-14.37,21.42-29.22,24.94.79-.73,1.58-1.48,2.39-2.21l4.71-4.22c4.95-4.4,6.33-9.68,4.73-15.66s-3.37-11.78-4.78-17.72c-2.34-9.83-1.64-19.38,4.05-28.31A35.53,35.53,0,0,1,431.86,286.33Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[8] ? selectedColor : 'white'}
            />
            <path className="b" d="M400.19,260.19c-3.8-.28-7.15-.62-10.52-.74-15.56-.58-30.75,1.07-45.12,6.76-17.85,7.07-30,19-37.21,35.12-1.75,3.91-3.75,7.71-7.06,10.79-2.35,2.19-5.06,3.8-8.71,3.06a8.87,8.87,0,0,1-5.36-3.53,19.11,19.11,0,0,1-3.81-11.5c-.34-12.12,5.47-21.69,15.58-29.34,8.6-6.51,18.81-10,29.66-12.22,13-2.61,26.21-2.9,39.47-2.26A278.52,278.52,0,0,1,399.37,260,7.22,7.22,0,0,1,400.19,260.19Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[9] ? selectedColor : 'white'}
            />
            <path className="b" d="M511.32,406.06c.17-3.69.5-7.38.5-11.07-.08-33.52-12.06-63-37.91-87.57a150.26,150.26,0,0,0-58.06-34.58,106.25,106.25,0,0,0-29.28-5.14c-18-.6-34.63,3.09-49.57,12.23-8.12,5-14.75,11.21-19.34,19.07-6.08,10.42-6.07,21-1.05,31.72s13.51,19.07,23.79,26a92.85,92.85,0,0,0,38,15c13,2,25.56,1.08,37.2-5a44.45,44.45,0,0,0,15.27-13.3,10.83,10.83,0,0,1,1.47-1.75,3.87,3.87,0,0,1-.26.67c-7.59,12.08-19.08,19.21-34.36,21.39-14,2-27.33-.31-40.21-5.14-14.51-5.45-27-13.44-36.61-24.64-5.75-6.65-9.85-14-11.41-22.41-1.86-10,.42-19.18,6.31-27.72,7.44-10.79,18-18.37,30.75-23.69,14.25-6,29.27-7.92,44.85-6.73,19.64,1.49,37.41,7.77,53.94,17.1,18.29,10.33,33.88,23.21,45.82,39.42a111.79,111.79,0,0,1,20.42,47.15,104,104,0,0,1,1.67,24c-.26,5-1,10-1.46,15Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[10] ? selectedColor : 'white'}
            />
            <path className="b" d="M400.52,247.23c-1.77-.46-3.53-.92-5.3-1.4a442.09,442.09,0,0,1-67.33-23.59c-18.27-8.26-35.56-17.86-51-29.93-10.32-8.06-19.63-16.92-26.65-27.53-3.07-4.64-5.68-9.46-6.69-14.83a15.41,15.41,0,0,1-.11-4.84c.37-2.81,2.65-3.8,5.48-2.53a15.4,15.4,0,0,1,5.94,4.93,85.44,85.44,0,0,1,6,8.62A98.18,98.18,0,0,0,279.73,179c16,14.8,34.72,26.55,54.28,37.26a554.83,554.83,0,0,0,65,30.07l1.62.66Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[11] ? selectedColor : 'white'}
            />
            <path className="b" d="M446.82,294.32a156.12,156.12,0,0,1,24.49,19.53c14.1,13.82,24.75,29.43,30.64,47.48a88,88,0,0,1,4.35,32.47,59.48,59.48,0,0,1-1.16,8.09c-1.31-4.31-2.43-8.67-4-12.92-3.93-11-9.89-20.91-19.27-29a48.19,48.19,0,0,0-17.85-10.15,1.16,1.16,0,0,1-.33-.17c.89-2.95,2-5.77,2.57-8.67,1.56-7.31.41-14.44-2.29-21.41-3.51-9.08-9.18-17-16.21-24.23C447.47,295,447.15,294.66,446.82,294.32Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[12] ? selectedColor : 'white'}
            />
            <path className="b" d="M565.6,352.68c1-2.85,2.16-5.68,3.1-8.55a108.43,108.43,0,0,0,5-25.5c2.67-34.7-9.23-65.34-32.3-92.92-4-4.72-7.5-9.73-11-14.73a18,18,0,0,1-2.47-5.76c-.55-2.08.8-3,2.94-2.08a35.17,35.17,0,0,1,6.69,3.88c7.59,5.72,13.63,12.68,19.06,20a126.27,126.27,0,0,1,21.73,46.05,104.47,104.47,0,0,1,2.34,35.23,110,110,0,0,1-14.45,43.5c-.18.3-.39.58-.58.88Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[13] ? selectedColor : 'white'}
            />
            <path className="b" d="M533.86,401c5.7-3.16,11.31-6.46,17.12-9.43a117.66,117.66,0,0,1,34.34-11.67c15.72-2.62,31-1.64,45.3,5.27a43.5,43.5,0,0,1,16.25,12.72c1.9,2.52,3.47,5.21,3.56,8.35s-2.26,5.07-5.9,4.8a24,24,0,0,1-9.66-2.92c-5-2.69-9.73-5.66-14.67-8.39a83.26,83.26,0,0,0-72.37-4.62c-4.28,1.68-8.34,3.81-12.5,5.74l-1.14.57Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[14] ? selectedColor : 'white'}
            />
            <path className="b" d="M501.16,80.26c-14.92,7-22.58,18.77-27.23,32.54a94.8,94.8,0,0,0-4.67,27.59c-.81,25.39,5.28,49.65,15.07,73.3,6,14.58,14,28.31,22.45,41.89a184.38,184.38,0,0,1,22.78,53A146.6,146.6,0,0,1,533.38,332a125.17,125.17,0,0,1-.36,24.86c-1.82,15.42-6.18,30.18-14.56,43.88a26.31,26.31,0,0,1-1.52,2.16c1.5-3.14,3.12-6.23,4.49-9.42a111.84,111.84,0,0,0,8.06-29.86,125.82,125.82,0,0,0,1.06-21.33,153.25,153.25,0,0,0-8.9-45.25,213.8,213.8,0,0,0-21.54-43.54c-13.33-21-23.47-43-29.35-66.63A165.62,165.62,0,0,1,465.91,154c-.56-13,.63-25.87,4.68-38.42,3.09-9.59,7.83-18.43,15.46-25.89C490.25,85.54,496.72,81.44,501.16,80.26Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[15] ? selectedColor : 'white'}
            />
            <path className="b" d="M542.11,388.88c16.22-11,33.66-19.66,53.91-22.87,18.1-2.88,35.29-.62,51.12,8.06,8,4.37,14.83,9.9,19.62,17.17a26.46,26.46,0,0,1,3.2,7.22c.86,3.05-1.21,4.6-4.61,3.72-3.78-1-6.8-3.13-9.75-5.33-4.29-3.22-8.35-6.71-12.81-9.73a77,77,0,0,0-36.44-12.81c-13.63-1.29-26.87.54-39.8,4.43a128,128,0,0,0-23.7,9.77Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[16] ? selectedColor : 'white'}
            />
            <path className="b" d="M398.16,249.71c-3.75-.41-7.51-.76-11.25-1.24a315.51,315.51,0,0,1-38.6-7.23c-22.15-5.68-43.13-13.65-62.22-25.33-8.62-5.27-16.68-11.13-23.26-18.45a49.32,49.32,0,0,1-4.17-5.63,9,9,0,0,1-1.12-2.78c-.55-2.09.54-3.22,2.92-2.8a14.37,14.37,0,0,1,4.91,1.63,136.62,136.62,0,0,1,13.25,8.74,239.22,239.22,0,0,0,47.86,29.07A335.75,335.75,0,0,0,396,249l2.19.47Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[17] ? selectedColor : 'white'}
            />
            <path className="b" d="M512.07,407c.74-3.4,1.58-6.91,2.24-10.45a91.33,91.33,0,0,0,1.22-21.71c-1.13-20.3-8-39-19.72-56.3C485.06,302.54,471.19,289,455.41,277c-17-13-35.8-23.64-55.32-33.29-18-8.9-36.23-17.48-54.25-26.38a310.59,310.59,0,0,1-44.13-25.91c-13.26-9.54-25.07-20.3-34.75-32.86a4.76,4.76,0,0,1-1-1.79c1.7,2,3.37,3.93,5.1,5.87,11.06,12.31,24.14,22.66,38.54,31.75,15.71,9.91,32.51,18.17,49.51,26.12,19.81,9.25,39.86,18.1,59.07,28.34,17.53,9.34,34.14,19.8,49,32.34,15.81,13.29,29.17,28.23,38.42,45.9a104.88,104.88,0,0,1,11.38,35,91.21,91.21,0,0,1-3.41,41c-.39,1.21-.88,2.4-1.34,3.59A1,1,0,0,1,512.07,407Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[18] ? selectedColor : 'white'}
            />
            <path className="b" d="M560,370.28c2.08-1.17,4-2.3,6-3.38,12.25-6.61,25.11-11.93,39.16-14.67,15.63-3,31-2.67,45.77,3.4,9.75,4,17.85,9.83,23.48,18.13a18.13,18.13,0,0,1,3.14,7.41c.52,3.43-1.55,5.13-5.28,4.23a23.32,23.32,0,0,1-8.22-4.21c-4.33-3.17-8.42-6.63-12.92-9.6a74.05,74.05,0,0,0-33.59-11.69c-18.29-1.9-35.49,1.65-52.09,8.26-1.74.7-3.44,1.5-5.16,2.25C560.34,370.43,560.28,370.38,560,370.28Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[19] ? selectedColor : 'white'}
            />
            <path className="b" d="M529.79,391.19c1.06-2.82,2.19-5.55,3.12-8.33A105.86,105.86,0,0,0,538,356.69a144.21,144.21,0,0,0-.71-28.1,191,191,0,0,0-9.11-38.54,72.2,72.2,0,0,1-4-16.34,20.9,20.9,0,0,1,.17-5.24c.4-2.52,2.38-3.24,4.5-1.58a23.22,23.22,0,0,1,4.52,4.67c4.89,6.78,7.74,14.33,10,22.08A114.9,114.9,0,0,1,547.7,327c-.26,22.32-6.21,43.41-17.29,63.38q-.17.3-.36.6C530,391,529.92,391.07,529.79,391.19Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[20] ? selectedColor : 'white'}
            />
            <path className="b" d="M397.32,255.78c-4.17-.79-8.33-1.65-12.53-2.35a199.76,199.76,0,0,0-24.94-2.56,139.3,139.3,0,0,0-39,3.52,82,82,0,0,0-32.95,16.21,24.71,24.71,0,0,1-9,4.84,13.49,13.49,0,0,1-4.43.47,3.86,3.86,0,0,1-3.81-3.59,10.56,10.56,0,0,1,1.92-7c3.59-5.74,9.07-9.74,15.35-12.92a77.11,77.11,0,0,1,26.76-7.63,122.07,122.07,0,0,1,15.39-1c7,.12,14.12.54,21.12,1.35a244,244,0,0,1,45.21,10.15l1,.34Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[21] ? selectedColor : 'white'}
            />
            <path className="b" d="M558.6,179.19c10.64,0,19.39,7.85,19.34,17.33s-8.9,17.34-19.46,17.26-19.21-7.83-19.26-17.3S547.94,179.19,558.6,179.19Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[22] ? selectedColor : 'white'}
            />
            <path className="b" d="M551.54,367.59c.58-1.42,1.17-2.83,1.72-4.25a123.58,123.58,0,0,0,8-31.82,153,153,0,0,0,1.06-18.76A106.64,106.64,0,0,0,541.78,251c-3-4.23-5.55-8.78-8.23-13.21a6.56,6.56,0,0,1-.85-2.57c-.08-.69,0-1.76.43-2a2.83,2.83,0,0,1,2.31.27,38.24,38.24,0,0,1,5.49,4.22c8.41,8.34,14.35,18,19.12,28.35a104.85,104.85,0,0,1,9.32,33.29c2.55,24.25-3.54,46.79-17.21,67.78-.12.19-.28.36-.42.54Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[23] ? selectedColor : 'white'}
            />
            <path className="b" d="M541,377.24a126.88,126.88,0,0,0,7.34-23.4,129.54,129.54,0,0,0,2.67-31c-.51-17.9-4.93-35.06-12.23-51.68a46.38,46.38,0,0,1-4-13.26c-.15-1.24-.5-2.77,1-3.41s2.49.65,3.39,1.51c3.46,3.36,5.78,7.38,7.9,11.48a106.73,106.73,0,0,1,10.32,31.94,109.28,109.28,0,0,1,1.39,23.61,107.47,107.47,0,0,1-17.44,53.72Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[24] ? selectedColor : 'white'}
            />
            <path className="b" d="M539.48,174.09c-5.84,0-10.54-4.11-10.58-9.29s4.66-9.44,10.42-9.46S550,159.47,550,164.71,545.32,174.07,539.48,174.09Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[25] ? selectedColor : 'white'}
            />
            <path className="b" d="M531.24,147.89c0,3.61-3.17,6.41-7.22,6.39s-7.25-3-7.21-6.46,3.25-6.39,7.28-6.39S531.24,144.25,531.24,147.89Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[26] ? selectedColor : 'white'}
            />
            <path className="b" d="M513.74,138.54a4.64,4.64,0,0,1-4.92-4.49,4.75,4.75,0,0,1,5-4.45,4.7,4.7,0,0,1,5,4.59C518.76,136.7,516.6,138.56,513.74,138.54Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[27] ? selectedColor : 'white'}
            />
            <path className="b" d="M510.91,120.53a2.6,2.6,0,0,1-2.73,2.53,2.73,2.73,0,0,1-2.92-2.54,2.86,2.86,0,0,1,2.86-2.51A2.69,2.69,0,0,1,510.91,120.53Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[28] ? selectedColor : 'white'}
            />
            <path className="b" d="M507.05,108.6a2,2,0,0,1-2.07,1.92,2.17,2.17,0,0,1-2.15-1.92,2,2,0,0,1,2.13-1.86A1.86,1.86,0,0,1,507.05,108.6Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[29] ? selectedColor : 'white'}
            />
            <path className="b" d="M506.29,98c-.63.37-1.23,1-1.89,1.06a1.26,1.26,0,0,1-1.49-1.46,2.14,2.14,0,0,1,1.53-1.27c.6,0,1.24.65,1.86,1Z" transform="translate(-150.77 -55.71)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[30] ? selectedColor : 'white'}
            />
            <path d="M150.77,406.51V55.82c29.41,0,58.25-.27,87.09.06,56.62.66,113.25,1.16,169.86,2.63A815.41,815.41,0,0,1,620.19,91.92c27.21,8.1,53.67,18.7,80.47,28.16,7.42,2.62,11.68,6.55,11.66,15.7q-.51,177.54-.34,355.1c0,1.33-.34,2.66-.84,6.32-7.34-6-13.67-11.24-20.11-16.35C644.58,443.93,590,426.09,532.6,418.19c-48.3-6.65-97.31-9.84-146.09-11.05-73.48-1.82-147-.58-220.54-.63ZM186.83,71.69V391.58c9.57,0,18.1-.11,26.62,0,84.32,1.33,168.72,1,253,4.6,63.49,2.71,125.83,14.72,183.88,42.59,16.34,7.85,31.75,17.63,48.34,26.94.5-5,1.32-9.5,1.32-14q.22-153.15.42-306.29c0-8.5-4.08-12-11.26-14.4-32.62-10.73-64.79-23.08-97.84-32.23C518.38,78.67,443.7,71.5,368.2,71.66,307.9,71.79,247.6,71.69,186.83,71.69ZM174.71,392.6V70.89l-14,.91V392.6Z" transform="translate(-150.77 -55.71)"
                fill={selectedStroke}
            />
        </svg>
    )
}

export default Flag8