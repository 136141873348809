import React, { useState } from 'react'
import "./../styles/about.css";
import "./../styles/home.css";


import { Link } from 'react-router-dom'
import Ad from '../Ad';

const About = () => {

    return (
        <div>
            <section className='about-header'>
                <h1>ABOUT BETWEEN BANDS</h1>
                <div id='about-header-image' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/about-background-v2.png)` }}>

                </div>
            </section>



            <section className='who-are-we'>
                <h4>Who Are We?</h4>
                <div id='who-are-we-line'></div>
                <p style={{ marginBottom: '1em' }}>Welcome to Between Bands, an exhilarating creation brought to you by ScholarshipAuditions.com. We’ve transformed the spaces "between" those thrilling marching band performances into a world of entertainment and discovery. At the heart of our mission is the recognition that the moments between each band's awe-inspiring show can often stretch into hours during a marching band competition. We're here to ensure that this downtime becomes a captivating part of the experience. With a treasure trove of articles that are both engaging and educational about the mesmerizing world of marching bands, as well as a delightful assortment of games and activities, Between Bands is your go-to source for fun and entertainment during those interludes. We're passionate about celebrating the incredible talent and dedication of marching bands, and we invite you to join us in making the most of the moments between, all while basking in the spirit of musical excellence and community. Welcome to our world. Welcome to Between Bands.
                </p>
                <p>Within the colorful tapestry of Between Bands, our array of games, articles, and activities promises to keep you thoroughly engaged during those gaps between marching band performances. Challenge your wits with a Tic-Tac-Toe-inspired game designed for all ages, or dive into a world of knowledge with our crossword puzzles that test your marching band acumen. For those craving some friendly competition, our trivia game will put your band expertise to the test. Unleash your creative side with our coloring book activity, where you can bring marching band visuals to life with your personal touch. And if you're feeling adventurous, embark on a digital scavenger hunt that will lead you to discover fascinating tidbits about the world of marching bands. We've thoughtfully curated these diversions to ensure your time "Between Bands" is just as memorable and engaging as the performances themselves.</p>
            </section>
            <section id='what-we-offer'>
                <h4>What do we offer?</h4>
                <div id='what-we-offer-line'></div>
                <div id='about-cards'>
                    <div className='about-card'>
                        <div className='about-card-title'>
                            <img src={process.env.PUBLIC_URL + 'images/article-icon.png'} height='35px'></img>
                            <h3 id='artic'>Articles</h3>
                            <div className='abt-crd-line'></div>
                        </div>
                        <p>We have lots of articles for you to read during down time. From the history of marching bands to past winners, immerse yourself in an article while you're waiting.</p>
                        <Link style={{ textDecoration: 'none', width: '100%' }} to='/articlespage'>
                            <button>Read</button>
                        </Link>

                    </div>
                    <div className='about-card'>
                        <div className='about-card-title'>
                            <img src={process.env.PUBLIC_URL + 'images/controller-purple.png'} height='35px'></img>
                            <h3>Games</h3>
                            <div className='abt-crd-line'></div>
                        </div>
                        <p>We have fun games for you to play in between sets. From our fun spin on tic tac toe to our crossword puzzle and trivia quiz, we will keep you entertained between bands.</p>
                        <Link style={{ textDecoration: 'none', width: '100%' }} to='/games' >
                            <button>Play</button>
                        </Link>

                    </div>
                    <div className='about-card'>
                        <div className='about-card-title'>
                            <img src={process.env.PUBLIC_URL + 'images/activities-icon.png'} height='35px'></img>
                            <h3>Activities</h3>
                            <div className='abt-crd-line'></div>
                        </div>
                        <p>In addition to games, we also have several activities for you to enjoy. Show your creativity with our coloring book, or test your eye by completing our scavenger hunt.</p>

                        <Link style={{ textDecoration: 'none', width: '100%' }} to='/activitiespage'>
                            <button>Play</button>
                        </Link>
                    </div>
                </div>
            </section>


            <Ad />
        </div>
    )
}

export default About
