
import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/pages/Home';
import Games from './components/pages/Games';
import ActivitiesPage from './components/pages/ActivitiesPage';
import ArticlesPage from './components/pages/ArticlesPage';
import About from './components/pages/About';
import { BrowserRouter as Router, Routes, Route, useLocation, useParams } from 'react-router-dom';
import PDFViewer from './PDFViewer.js';
import './App.css';

import TicTacToe from "./components/pages/Tic-Tac-Toe";
import CrackingTheCode from "./components/pages/CrackingTheCode";
import MarchingToSuccess from "./components/pages/MarchingToSuccess";
import MarchingContests from "./components/pages/MarchingContests";
import ASymphonyOfSupport from "./components/pages/ASymphonyOfSupport";
// import WhoAreTheseBandMembers from './components/pages/WhoAreTheseBandMembers';
import HarmonizingHearts from "./components/pages/HarmonizingHearts";
import MarchingBandJokes from "./components/pages/MarchingBandJokes";
import WinnersOfThePast from "./components/pages/WinnersOfThePast";
import HistoryOfMarchingBands from "./components/pages/HistoryOfMarchingBands";
import NextSaturday from "./components/pages/NextSaturday";
import SymphonyOfAspirations from "./components/pages/SymphonyOfAspirations";
import MarchingBandsUnveilingTheRhythmOfStyle from "./components/pages/MarchingBandsUnveilingTheRhythmOfStyle";
import MarchingBandWhereExcellenceAndSuccessUnite from "./components/pages/MarchingBandWhereExcellenceAndSuccessUnite";
import ColoringBook from './components/pages/ColoringBook';
import ColoringOptions from './components/pages/ColoringOptions';
import MarchingBandAFusion from './components/pages/MarchingBandAFusion';
import EvolutionOfMarchingBandUniforms from './components/pages/EvolutionOfMarchingBandUniforms';
import TRiviaHome from './components/pages/TriviaHome';
import ScavengerHunt from './components/pages/ScavengerHunt';
import Recipes from './components/pages/Recipes.js';

import withLoading from './components/Loading/loading';
import CrossWord from './components/pages/CrossWord';



const HomeWL = withLoading(Home);
const TicTacToeWL = withLoading(TicTacToe);
const MBWEASU_WL = withLoading(MarchingBandWhereExcellenceAndSuccessUnite);
const GamesWL = withLoading(Games);
const SymphonyOfAspirationsWL = withLoading(SymphonyOfAspirations);
const HarmonizingHeartsWL = withLoading(HarmonizingHearts);
const RecipesWL = withLoading(Recipes);
// const WhoAreTheseBandMembersWL = withLoading(WhoAreTheseBandMembers);
const MBURSWL = withLoading(MarchingBandsUnveilingTheRhythmOfStyle);
const HistoryOfMarchingBandsWL = withLoading(HistoryOfMarchingBands);
const WinnersOfThePastWL = withLoading(WinnersOfThePast);
const MarchingBandAFusionWL = withLoading(MarchingBandAFusion);
const NextSaturdayWL = withLoading(NextSaturday);
const MarchingToSuccessWL = withLoading(MarchingToSuccess);
const CrackingTheCodeWL = withLoading(CrackingTheCode);
const ASymphonyOfSupportWL = withLoading(ASymphonyOfSupport);
const MarchingContestsWL = withLoading(MarchingContests);
const MarchingBandJokesWL = withLoading(MarchingBandJokes);
const AboutWL = withLoading(About);
const ColoringOptionsWL = withLoading(ColoringOptions);
const ColoringBookWL = withLoading(ColoringBook);
const EOMBUWL = withLoading(EvolutionOfMarchingBandUniforms);
const ActivitiesPageWL = withLoading(ActivitiesPage);
const ArticlesPageWL = withLoading(ArticlesPage);
const TRiviaHomeWL = withLoading(TRiviaHome);
const ScavengerHuntWL = withLoading(ScavengerHunt);
const CrossWordWL = withLoading(CrossWord);

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main className='content'>
          <Routes>

            <Route path="/" element={<HomeWL />} />
            <Route path="/games" element={<GamesWL />} />
            <Route path="/tic-tac-toe" element={<TicTacToeWL />} />
            <Route path="/crackingTheCode" element={<CrackingTheCodeWL />} />
            <Route path="/marchingToSuccess" element={<MarchingToSuccessWL />} />
            <Route path="/marchingContests" element={<MarchingContestsWL />} />
            <Route path="/aSymphonyOfSupport" element={<ASymphonyOfSupportWL />} />
            <Route path="/MarchingBandAFusion" element={<MarchingBandAFusionWL />} />
            <Route path="/harmonizingHearts" element={<HarmonizingHeartsWL />} />
            <Route path="/recipes" element={<RecipesWL />} />
            {/* <Route path="/WhoAreTheseBandMembers" element={<WhoAreTheseBandMembersWL />} /> */}
            <Route path="/marchingBandJokes" element={<MarchingBandJokesWL />} />
            <Route path="/evolutionOfMarchingBandUniforms" element={<EOMBUWL />} />
            <Route path="/winnersOfThePast" element={<WinnersOfThePastWL />} />
            <Route path="/symphonyOfAspirations" element={<SymphonyOfAspirationsWL />} />
            <Route path="/nextSaturday" element={<NextSaturdayWL />} />
            <Route path="/MarchingBandsUnveilingTheRhythmOfStyle" element={<MBURSWL />} />
            <Route path="/historyOfMarchingBands" element={<HistoryOfMarchingBandsWL />} />
            <Route path="/marchingBandWhereExcellenceAndSuccessUnite" element={<MBWEASU_WL />} />
            <Route path="/about" element={<AboutWL />} />
            <Route path="/coloringbookoptions" element={<ColoringOptionsWL />} />
            <Route path="/coloringbook/:id" element={<ColoringBookWL />} />
            <Route path="/activitiespage" element={<ActivitiesPageWL />} />
            <Route path="articlespage" element={<ArticlesPageWL />} />
            <Route path="/trivia" element={<TRiviaHomeWL />} />
            <Route path="/scavengerhunt" element={<ScavengerHuntWL />} />
            <Route path="/pdf/:filename" element={<PDFViewer />} />
            <Route path="/crossword" element={<CrossWordWL />} />
          </Routes>
        </main>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
