import React from 'react';
import './styles/home.css';
import { Link } from "react-router-dom";

const ActivitiesHome = () => {
    return (
        <div id='activities-home-main'>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1em' }}>
                <img className='activities-icon' src={process.env.PUBLIC_URL + 'images/activities-icon-white.png'}></img>
                <h2>Activities</h2>
            </div>


            <div id='activity-cards-home'>
                <div className='activity-card-home'>
                    <div className='activity-image-bg'>
                        <div className='activity-card-home-img' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/coloring-book.jpg)` }} >
                        </div>
                    </div>
                    <div className='activity-card-line'></div>
                    <div className='activity-card-content-home'>

                        <h3 className='coloring-book-h'>Coloring Book</h3>

                        <p>You, the Design Maestro

                            Imagine this: The band director rushes in with a look of pure panic. He's been wrestling with the closing number, but there are still thirty-two measures to go, and the flags and shako designs are far from complete. He delivers the bombshell – they're due at the uniform manufacturer's in under an hour. The pressure's on, and the only savior on the horizon is you.

                            He hands you the specs, and with a swift directive, brings you to the coloring book on BetweenBands.com. Then, in a whirlwind of stress, he disappears into the unknown.

                            But you're no band parents; you're a creative virtuoso with a dash of drama. Time's ticking, and your imagination is poised to conjure flag designs that'll leave everyone spellbound. Let the show begin!</p>

                        <Link to='/coloringbookoptions' style={{ textDecoration: 'none', width: '100%' }}>
                            <button className='activity-btn-home'>Start Coloring</button>
                        </Link>

                    </div>


                </div>

                <div className='activity-card-home'>
                    <div className='activity-image-bg'>
                        <div className='activity-card-home-img' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/scavenger.png)` }} >
                        </div>
                    </div>
                    <div className='activity-card-line'></div>
                    <div className='activity-card-content-home'>

                        <h3 className='coloring-book-h'>Scavenger Hunt</h3>

                        <p>
                            Get ready for an adventure like no other! No stress, just pure fun! View and enjoy the artwork by our creative team and find all of the hidden items in our very own marching band scavenger hunt. With a swift stroke of your imagination, you're set to craft a clue that'll have participants grinning from ear to ear. Let the good times roll as you add a dash of excitement to the game, making it an unforgettable experience for all involved. Game on!</p>

                        <Link to='/scavengerhunt' style={{ textDecoration: 'none', width: '100%' }}>
                            <button className='activity-btn-home'>Start Searching</button>
                        </Link>

                    </div>


                </div>
            </div>





        </div>
    )
}

export default ActivitiesHome
