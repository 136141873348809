import React, { useState } from 'react'

const Flag10 = ({ selectedColor, onPathClick, selectedStroke }) => {

    const [isClicked, setIsClicked] = useState(new Array(6).fill(false));

    const handleClick = (event, index) => {
        onPathClick(event);


        setIsClicked((prevIsClicked) =>
            prevIsClicked.map((clicked, i) => (i === index ? !clicked : clicked))
        );
    };
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 641.2 441.48"
            className='svg-temp'
        >
            <defs>
                <style>
                    {
                        `
                     .b{stroke:${selectedStroke};stroke-miterlimit:10;stroke-width:3px;}
                `
                    }
                </style>
            </defs>
            <path className="a" d="M108.48,103.85c60.78,0,121.08.11,181.38,0,75.49-.16,150.17,7,223.06,27.17,33.05,9.15,65.22,21.5,97.84,32.23,7.18,2.37,11.29,5.91,11.26,14.4q-.42,153.15-.41,306.29c0,4.47-.83,8.94-1.33,14-16.59-9.32-32-19.1-48.34-26.94-58.05-27.87-120.39-39.88-183.88-42.59-84.23-3.6-168.63-3.28-253-4.6-8.52-.14-17,0-26.62,0Z" transform="translate(-72.42 -87.88)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[0] ? selectedColor : 'white'}
            />
            <path className="a" d="M96.36,424.76h-14V104l14-.92Z" transform="translate(-72.42 -87.88)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[1] ? selectedColor : 'white'}
            />
            <path className="b" d="M348.31,227a28.54,28.54,0,0,1,5.17,10.77c2.74,12.1-2.57,26-14.5,31.18-9.54,4.16-23.81,3.22-32-6.24a30.45,30.45,0,0,1-7-15.5c-1.92-12.24.58-23.51,8-33.52a45.38,45.38,0,0,1,26-17.24c22.79-5.81,44.07-2.37,62.87,12.21a65.05,65.05,0,0,1,24.74,40.68c1.05,5.64.89,11.44.78,17.19-.63,32.84-11.08,62.25-31.48,87.93S344.32,397,312.57,405.14a138.52,138.52,0,0,1-26.26,4.06,145.56,145.56,0,0,1-146.79-98.3,145.78,145.78,0,0,1-6.78-30.19,145.84,145.84,0,0,1,11-74.52,146.21,146.21,0,0,1,95.72-83,119.2,119.2,0,0,1,21.21-3.94c.46,0,1-.47,1.26.33a194.77,194.77,0,0,0-17.86,9.26c-30.52,18.49-51.83,44.41-63.08,78.38a101.75,101.75,0,0,0,4.08,77.33,99.56,99.56,0,0,0,24.31,33.26A89.2,89.2,0,0,0,252.08,339a102.37,102.37,0,0,0,26.37,1.68c20.5-1.26,39-7.89,54.9-21.14,15.62-13,25.9-29.47,29.6-49.63,1.44-7.83,2.16-15.78-.12-23.64A36.27,36.27,0,0,0,349.25,227a7.08,7.08,0,0,0-.89-.51C348,226.62,348.3,226.81,348.31,227Z" transform="translate(-72.42 -87.88)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[2] ? selectedColor : 'white'}
            />
            <path className="b" d="M407.15,200a75.92,75.92,0,0,0-11.75-8.82,77.71,77.71,0,0,0-29.82-10.33,95.32,95.32,0,0,0-32.49.94,31.6,31.6,0,0,0-10.69,4c-.84.54-1,.1-1.21-.5a48.45,48.45,0,0,0-7.09-11.82,60.16,60.16,0,0,0-10.28-10.08,12.75,12.75,0,0,0-1.31-.78,30.93,30.93,0,0,1,2.34,7c2.66,13.57-5.2,27.49-17.88,31.57A26.31,26.31,0,0,1,253.55,184c-4.86-15.17-2.08-29.22,8.12-41.54,7.53-9.1,17.62-13.86,29-16.08,17-3.3,33.26-.49,49,6.38a125.29,125.29,0,0,1,31.42,20,143.55,143.55,0,0,1,35.6,45.67c.2.4.39.8.56,1.2C407.25,199.71,407.19,199.82,407.15,200Z" transform="translate(-72.42 -87.88)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[3] ? selectedColor : 'white'}
            />
            <path className="b" d="M350.3,275.64c-14.75,37.81-58,63.73-105.11,48.11-46.61-15.45-64-63.51-54.35-98.65a11.71,11.71,0,0,1,.5,3.51,61.85,61.85,0,0,0,11.65,28c11.22,15.5,26.31,25,45.28,27.72,15.12,2.21,29.74.25,43.44-6.91a38.06,38.06,0,0,0,5.87-3.64,1.11,1.11,0,0,1,1.73.1A38.89,38.89,0,0,0,323,284.18c8.91.75,17.19-.89,24.51-6.29C348.43,277.21,349.31,276.44,350.3,275.64Z" transform="translate(-72.42 -87.88)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[4] ? selectedColor : 'white'}
            />
            <path className="c" d="M261.94,119.59c-.33-.44-.84-.2-1.26-.33.67-.4,1.42-.2,2.13-.3.29-.08.37,0,.26.29Z" transform="translate(-72.42 -87.88)"

            />
            <path className="d" d="M263.07,119.25l-.26-.29a1,1,0,0,1,1-.17A1,1,0,0,1,263.07,119.25Z" transform="translate(-72.42 -87.88)"

            />
            <path className="e" d="M348.31,227l-.8-.76c.48-.27.64.07.85.29Z" transform="translate(-72.42 -87.88)"

            />
            <path className="b" d="M626.23,397c-1.72-.25-3.44-.54-5.15-.89a84.46,84.46,0,0,1-41-21.16,99.48,99.48,0,0,1-23.37-33.26,105.44,105.44,0,0,1-3.91-77.33c10.81-34,31.29-59.9,60.62-78.39,4.15-2.62,8.46-4.91,12.83-7.1v-2.15A116.22,116.22,0,0,0,609,180.35a137.1,137.1,0,0,0-59.62,34.87A145.25,145.25,0,0,0,517,263.33a151.35,151.35,0,0,0-10.58,74.53,152.13,152.13,0,0,0,6.51,30.19,146,146,0,0,0,26.27,47.83,139.28,139.28,0,0,0,40.23,33.64,134.48,134.48,0,0,0,46.83,15.81Z" transform="translate(-72.42 -87.88)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[5] ? selectedColor : 'white'}
            />
            <path className="b" d="M627.19,178.41l3.38-1.67c-.23-.8-.78-.36-1.22-.34-.72.07-1.44.14-2.16.23Z" transform="translate(-72.42 -87.88)"

            />
            <path className="b" d="M626.23,342.26a73.78,73.78,0,0,1-8.81-.81c-18.23-2.77-32.73-12.22-43.51-27.73a63.24,63.24,0,0,1-11.2-28,12,12,0,0,0-.48-3.51c-9.29,35.15,7.44,83.2,52.23,98.65a85.73,85.73,0,0,0,11.77,3.16Z" transform="translate(-72.42 -87.88)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[6] ? selectedColor : 'white'}
            />
            <path className="c" d="M630.57,176.74c-.32-.45-.82-.2-1.22-.34.65-.39,1.37-.2,2-.29.28-.08.36,0,.25.29Z" transform="translate(-72.42 -87.88)"

            />
            <path className="d" d="M631.65,176.4l-.25-.29a.9.9,0,0,1,1-.17A.94.94,0,0,1,631.65,176.4Z" transform="translate(-72.42 -87.88)"

            />
            <path className="e" d="M713.57,284.11l-.77-.76c.46-.26.62.07.82.3Z" transform="translate(-72.42 -87.88)" />
            <path d="M622.32,152.25c-26.81-9.46-53.27-20.06-80.48-28.17a815.4,815.4,0,0,0-212.47-33.4c-56.6-1.48-113.24-2-169.86-2.64-28.84-.33-57.68-.05-87.09-.05V438.67h15.2c73.51.06,147.07-1.19,220.54.63,48.78,1.21,97.79,4.4,146.09,11.06,57.37,7.9,112,25.74,158.43,62.65,6,4.72,11.8,9.57,18.44,15V170c0-.65,1-.56,1,.08V528.81l.67.55c.5-3.66.84-5,.84-6.31q.09-177.55.34-355.11C634,158.8,629.73,154.86,622.32,152.25Zm-526,272.51h-14V104l14-.92Zm525.25,59.16c0,4.47-.83,8.94-1.33,14-16.59-9.32-32-19.1-48.34-26.94-58.05-27.87-120.39-39.88-183.88-42.59-84.23-3.6-168.63-3.28-253-4.6-8.52-.14-17,0-26.62,0V103.85c60.78,0,121.08.11,181.38,0,75.49-.16,150.17,7,223.06,27.17,33.05,9.15,65.22,21.5,97.84,32.23,7.18,2.37,11.29,5.91,11.26,14.4Q621.6,330.78,621.61,483.92Z" transform="translate(-72.42 -87.88)"

                fill={selectedStroke}
            />
        </svg>
    )
}

export default Flag10