import React, { useState } from 'react'

const Flag12 = ({ selectedColor, onPathClick, selectedStroke }) => {
  const [isClicked, setIsClicked] = useState(new Array(24).fill(false));

  const handleClick = (event, index) => {
    onPathClick(event);


    setIsClicked((prevIsClicked) =>
      prevIsClicked.map((clicked, i) => (i === index ? !clicked : clicked))
    );
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 561.55 446.66"
      className='svg-temp'
    >
      <defs>
        <style>
          {
            `
          .b,.c,.d,.e,.f,.g,.h,.j{stroke:
          ${selectedStroke};stroke-miterlimit:10;}.b{stroke-width:3.05px;}.c{stroke-width:3.07px;}.d,.j{stroke-width:3px;}.e{stroke-width:2.99px;}.f{stroke-width:3.04px;}.g{stroke-width:3.07px;}.h{stroke-width:3.05px;}
        `
          }

        </style>
      </defs>
      <path
        className="a"
        d="M643.84,191.16q-.51,177.55-.34,355.11c0,1.32-.34,2.65-.84,6.31L642,552v5.28c0,.65-1,.56-1-.08v-6c-6.63-5.42-12.49-10.27-18.43-15-40-31.83-86.19-49.48-134.91-58.79-7.78-1.5-15.62-2.77-23.52-3.86q-18.24-2.52-36.6-4.4c-22.93-2.36-46-4-69-5.16q-20.24-1-40.48-1.5-12.63-.31-25.28-.51c-65.06-1-130.17-.07-195.26-.12H82.29V111.2c29.41,0,58.26-.27,87.09.06,56.63.66,113.26,1.16,169.86,2.64a815.47,815.47,0,0,1,212.48,33.4c27.2,8.11,53.67,18.71,80.47,28.17C639.6,178.08,643.87,182,643.84,191.16Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[0] ? selectedColor : 'white'}
      />
      <path className="b" d="M170.67,126.49a1.52,1.52,0,0,0,.55.81c4.34,4.07,8.56,8.26,12.75,12.48,5.93,6,11.58,12.22,17.2,18.49,2.38,2.65,4.67,5.37,6.94,8.12,3.12,3.77,6.24,7.54,9.28,11.38,3.47,4.35,6.9,8.74,10.23,13.2q4.8,6.43,9.47,13,4.5,6.27,8.85,12.66c5.22,7.68,10.28,15.47,15.25,23.32s9.58,15.53,14.24,23.38c2.5,4.21,4.92,8.46,7.33,12.72q5.46,9.6,10.85,19.24c2.28,4.09,4.51,8.21,6.76,12.32q3.11,5.67,6.19,11.35,4.05,7.43,8.08,14.87c1.4,2.57,2.78,5.15,4.21,7.7.34.59.35,1.29.78,1.83a.44.44,0,0,1,0,.48,8.2,8.2,0,0,1-1.12,1.94,28.24,28.24,0,0,0-2.13,2.92c-.82,1.1-1.66,2.19-2.48,3.29-.53.71-1.1,1.37-1.67,2s-.78.61-1.27,0-1.09-1.49-1.6-2.26q-4.82-7.26-9.6-14.55-6.37-9.69-12.77-19.38c-3.71-5.63-7.39-11.27-11.16-16.84q-7.18-10.6-14.49-21.12-5.65-8.08-11.5-16-4-5.46-8.12-10.86-4.44-5.85-9.07-11.55-5.6-6.93-11.33-13.76c-4.59-5.45-9.43-10.67-14.22-15.94-2.65-2.92-5.38-5.75-8.13-8.57-4.48-4.58-9.08-9-13.78-13.4Q173.52,178.9,161,169.13q-11.75-9.19-24-17.67c-6.37-4.42-12.81-8.74-19.29-13a7.11,7.11,0,0,0-2.52-1.37l.65-13.27Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[1] ? selectedColor : 'white'}
      />
      <path className="c" d="M115.13,147.29c4.82,2.8,9.48,5.88,14.12,9q12.76,8.57,24.86,18.17,13.35,10.6,25.91,22.27,7,6.56,13.86,13.39c3.16,3.18,6.3,6.39,9.35,9.7,3.81,4.15,7.6,8.3,11.36,12.5,4,4.52,7.93,9.2,11.85,13.84,2.72,3.21,5.35,6.52,7.91,9.87,3.7,4.84,7.42,9.66,11.05,14.57q6.28,8.5,12.33,17.24c4.33,6.23,8.64,12.5,12.82,18.85,2.53,3.84,5.12,7.63,7.65,11.46,3.3,5,6.63,10,9.85,15,2.47,3.87,5,7.69,7.52,11.54,3,4.61,6.09,9.17,9.12,13.77.55.84,1,1.74,1.52,2.61a1.11,1.11,0,0,1,.1.91c-.34,1.1-.71,2.18-1.16,3.25-.64,1.5-1.68,2.8-2,4.48a3.37,3.37,0,0,1-.48,1.08,7.85,7.85,0,0,0-1.13,2.68,3.66,3.66,0,0,1-.48,1.09c-.75,1.34-1,1.38-2,.19-2.07-2.5-4.11-5-6.07-7.64-1.38-1.85-2.9-3.58-4.31-5.41-2.93-3.84-6-7.58-8.86-11.44-1.3-1.74-2.66-3.44-4-5.13-2.32-2.87-4.54-5.83-6.77-8.79-1.15-1.52-2.41-3-3.57-4.49-2.34-3.09-4.81-6.06-7.18-9.12s-5-6.23-7.47-9.39c-7.62-9.8-15.76-19.11-23.84-28.46-4-4.66-8.25-9.12-12.46-13.58a449.65,449.65,0,0,0-32.45-31.16,324.43,324.43,0,0,0-28.93-22.45,245.66,245.66,0,0,0-33.29-19.35c-2-.92-3.92-1.8-5.88-2.7a3.25,3.25,0,0,0-1.21-.32Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[2] ? selectedColor : 'white'}
      />
      <path className="d" d="M112,340.25c2.68,0,5.37-.16,8.05-.07A97.25,97.25,0,0,1,148,345.43a166.57,166.57,0,0,1,30.88,14.34c7.09,4.11,14,8.58,20.67,13.27q10.81,7.54,21.3,15.55l13.13,10c5.54,4.23,11.06,8.47,16.61,12.69,1.71,1.3,3.47,2.52,5.23,3.76,2.35,1.65,4.69,3.33,7.09,4.91,4.93,3.27,9.93,6.44,15,9.46,5.34,3.18,10.87,6,16.4,8.8a8.09,8.09,0,0,1,2.1,1.81,1.15,1.15,0,0,1,.28.65,36.71,36.71,0,0,0,.77,5.22,26.24,26.24,0,0,0,1.08,5.4,3,3,0,0,1,.09.95,10.59,10.59,0,0,0,.4,3,2.1,2.1,0,0,1,0,1.31.75.75,0,0,1-.92.58c-.48-.1-1-.22-1.43-.34-3.63-.91-7.18-2.06-10.74-3.22a155.21,155.21,0,0,1-17.09-6.76q-6.47-3-12.72-6.4c-9-4.9-17.48-10.74-26-16.37-7.49-4.92-14.91-9.93-22.42-14.82-4.95-3.23-10-6.33-15.09-9.35-4.47-2.65-9-5.23-13.58-7.63a165.28,165.28,0,0,0-27.46-11.61,99,99,0,0,0-15.81-3.54,92.14,92.14,0,0,0-12.42-.76,56.91,56.91,0,0,0-13.18,1.47Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[3] ? selectedColor : 'white'}
      />
      <path className="d" d="M117.85,220a13.08,13.08,0,0,1,3.48,1.16,176.94,176.94,0,0,1,22.84,10.82,241,241,0,0,1,22,14c8.05,5.77,15.78,12,23.28,18.58q9,7.9,17.61,16.37c3.53,3.49,7,7,10.43,10.63q5.88,6.19,11.63,12.52,3.7,4.08,7.34,8.23c6.93,7.88,13.64,16,20.32,24.1,2.85,3.47,5.76,6.9,8.52,10.46,2,2.56,4.13,4.94,6.08,7.51,3.48,4.59,7.35,8.78,10.87,13.3,2.3,3,4.8,5.72,7.07,8.69,1.7,2.24,3.66,4.21,5.41,6.39a3.52,3.52,0,0,1,.85,2.75,19.43,19.43,0,0,1-1,5,5.3,5.3,0,0,0-.3,1c-.37,2.62-1.19,5.12-1.69,7.7-.34,1.79-.8,2-2,.85-1.68-1.52-3.29-3.12-4.9-4.72s-3.44-3.46-5.15-5.2q-2.88-2.91-5.75-5.84c-2.36-2.41-4.71-4.81-7.06-7.23-1.55-1.6-3.06-3.24-4.61-4.85-2.75-2.85-5.39-5.82-8.17-8.63-1.78-1.78-3.45-3.7-5.18-5.54q-3.74-4-7.48-7.89-3-3.21-6.11-6.4-3.69-3.81-7.42-7.58c-3-3.05-6.05-6.09-9.12-9.08-3.33-3.27-6.72-6.47-10.13-9.64-7.17-6.66-14.51-13.07-22.11-19.14a240.64,240.64,0,0,0-29.49-20.41,151,151,0,0,0-28.78-13.11c-4-1.29-8-2.36-12.05-3.28a11.76,11.76,0,0,0-1.21-.15Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[4] ? selectedColor : 'white'}
      />
      <path className="d" d="M114.86,281.18a44.74,44.74,0,0,1,7.7,1.23,113.12,113.12,0,0,1,21.35,6.82,146.62,146.62,0,0,1,14.6,7.23,216.17,216.17,0,0,1,27.87,19q8.9,7,17.36,14.65t16.37,15.3c5.63,5.49,11.19,11.05,16.76,16.6,4.68,4.66,9.37,9.31,14,14q5.65,5.76,11.43,11.41c3.67,3.57,7.29,7.21,11,10.76,2.42,2.32,5,4.49,7.39,6.82,3,2.85,6.12,5.48,9.13,8.28a4.09,4.09,0,0,1,1.23,3,23.18,23.18,0,0,1-.26,4.47,14.23,14.23,0,0,0-.1,2.62c0,.22,0,.44,0,.66-.15,2.58-.31,5.16-.46,7.74,0,.57-.36.69-.75.48s-.89-.6-1.35-.88c-4.2-2.65-8.25-5.58-12.27-8.53-3.69-2.7-7.29-5.54-10.9-8.37-8.7-6.82-17-14.15-25.32-21.53-7.58-6.74-15.18-13.45-22.89-20-4.15-3.52-8.3-7-12.54-10.41-7.15-5.69-14.4-11.24-21.93-16.34-4.5-3.06-9.07-6-13.76-8.69-10.79-6.2-22-11.29-34-14.22a89.34,89.34,0,0,0-12.14-2.11c-2.19-.22-4.37-.39-6.57-.51-1-.05-2,.1-2.92.09Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[5] ? selectedColor : 'white'}
      />
      <path className="d" d="M249.93,118.48a28.24,28.24,0,0,0,2,4.07q7.28,14.24,13.95,28.79,4.5,9.78,8.8,19.67c2.19,5,4.34,10.1,6.49,15.17q5.61,13.22,10.92,26.59,3.58,9,7.06,18.07,3.11,8,6.13,16.06,3.84,10.24,7.62,20.53c1.84,5,3.69,9.93,5.5,14.91,2.09,5.72,4.13,11.45,6.2,17.17,1.19,3.26,2.47,6.49,3.57,9.79,1.42,4.2,3.1,8.29,4.49,12.5a2.7,2.7,0,0,1,.24,1.15,2.13,2.13,0,0,0,.43,1.45,1.13,1.13,0,0,1-.45,1.82,3.83,3.83,0,0,0-1.18.92c-1.27,1.57-2.84,2.8-4.13,4.34a7.64,7.64,0,0,1-1.14,1,5,5,0,0,0-1,1c-.64.91-1.1.85-1.61-.2s-.89-2-1.34-2.95c-1.28-2.84-2.52-5.69-3.85-8.5-2.23-4.73-4.33-9.52-6.5-14.28q-2.82-6.19-5.68-12.37c-3.05-6.54-6.14-13.05-9.17-19.6-4.87-10.56-10-21-15.13-31.39-3.9-7.89-7.93-15.7-12-23.49q-6.87-13.12-14.12-26-7.38-13.1-15.24-25.84Q234.55,158.77,228,149q-4.3-6.43-8.84-12.71c-3.95-5.46-7.93-10.87-12.1-16.13-.71-.9-1.38-1.84-2.11-2.72-.27-.32-.56-.66-.36-1.16Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[6] ? selectedColor : 'white'}
      />
      <path className="e" d="M609.08,169c.11.35-.1.54-.37.74-1.36,1-2.68,2-4.05,3-3.1,2.25-6.08,4.63-9.13,6.93-2.87,2.16-5.55,4.51-8.39,6.69-1.83,1.41-3.61,2.85-5.36,4.33-4,3.45-8.1,6.89-12,10.42-5.42,4.85-10.75,9.79-15.93,14.85q-8.82,8.61-17.23,17.55-10.58,11.27-20.45,23-11.17,13.32-21.49,27.21c-3.23,4.37-6.51,8.71-9.7,13.11-3.39,4.68-6.69,9.41-10,14.12-2.63,3.72-5.33,7.4-7.85,11.18s-5.09,7.36-7.56,11.09c-.64,1-1.34,1.93-2,2.91-.47.74-.81.77-1.53.2a15,15,0,0,1-2.19-1.94,9.88,9.88,0,0,0-2.37-2.08c-.9-.53-1.22-1.47-1.85-2.19-.21-.24,0-.49.12-.72,1.82-3.25,3.63-6.51,5.46-9.76q2.55-4.51,5.15-9c1.85-3.2,3.73-6.38,5.59-9.58,1.76-3,3.51-6.07,5.28-9.1q8-13.72,16.52-27.22c3.36-5.39,6.82-10.73,10.32-16,5-7.56,10.07-15.06,15.32-22.46q8.65-12.18,17.81-24,13.73-17.73,29-34.35c.36-.4.84-.71,1-1.23Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[7] ? selectedColor : 'white'}
      />
      <path className="d" d="M632.3,451.12a5.58,5.58,0,0,1-1.89-.53,90.55,90.55,0,0,0-21.57-5.65c-3.59-.51-7.2-.88-10.83-1a155.2,155.2,0,0,0-16.29.18,232.51,232.51,0,0,0-25.32,3.12c-8.69,1.52-17.26,3.31-25.74,5.31-7,1.65-14,3.33-21,5-4.86,1.15-9.79,2.13-14.77,3S485,462,479.94,462.57c-4.36.46-8.76.77-13.17,1-1.34.07-2.69,0-4,.07-.72,0-.91-.17-.74-.68a13.79,13.79,0,0,1,1.4-2.14,11.92,11.92,0,0,0,1.65-2.94.85.85,0,0,1,.25-.51c1.23-.87,1.45-2.15,2.31-3.18a7.44,7.44,0,0,0,1.36-2.44,2.52,2.52,0,0,1,1.1-1.5,8.11,8.11,0,0,1,2.19-.83c4.92-1,9.77-2.15,14.57-3.4,4.36-1.14,8.72-2.3,13-3.64,4.49-1.4,9-2.74,13.43-4.25,3-1,6.11-2,9.14-3q7.35-2.44,14.84-4.67c10.56-3.14,21.22-6.08,32.28-8.27A190.82,190.82,0,0,1,592.2,419c3.26-.25,6.53-.37,9.83-.39s6.76.13,10.13.37A94.46,94.46,0,0,1,631,422.2a16.37,16.37,0,0,1,2.71.83Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[8] ? selectedColor : 'white'}
      />
      <path className="d" d="M635.93,247.44c-2.2.94-4.52,1.48-6.77,2.28-4.53,1.6-9,3.32-13.46,5.25a222.6,222.6,0,0,0-25.91,13.46c-6,3.58-11.78,7.42-17.5,11.44q-6.1,4.31-12,8.92c-3.83,3-7.64,6-11.36,9.21-4,3.38-7.93,6.8-11.81,10.32-3.22,2.92-6.42,5.86-9.56,8.89-1.7,1.65-3.5,3.19-5.16,4.89-3.19,3.29-6.49,6.46-9.68,9.75-2.77,2.86-5.53,5.75-8.29,8.63-2.94,3.06-5.81,6.19-8.72,9.29-3.1,3.31-6.1,6.75-9.21,10.06s-5.91,6.58-8.86,9.87a5.23,5.23,0,0,1-1.69,1.27c-.95.47-.88.51-1.27-.56-1.11-3.06-2-6.24-3.13-9.26a2.55,2.55,0,0,1,.48-3c3.3-4.15,6.54-8.36,9.82-12.52,1.75-2.22,3.6-4.35,5.29-6.61,3.3-4.44,6.84-8.66,10.31-12.94,3.69-4.54,7.4-9.06,11.23-13.47,1.82-2.09,3.56-4.26,5.37-6.35,2-2.32,4.08-4.57,6.09-6.89,2.71-3.12,5.58-6.07,8.36-9.12,1-1.08,1.95-2.15,3-3.17,4.9-4.93,9.67-10,14.75-14.74,4.3-4,8.6-8,13-11.88,5.68-5,11.44-9.82,17.36-14.46s11.76-8.93,17.82-13.1q10.38-7.15,21.25-13.42c5.91-3.4,11.89-6.64,18-9.53,1-.47,2-1,3-1.44.38-.18.75-.39,1.17-.15Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[9] ? selectedColor : 'white'}
      />
      <path className="d" d="M638.48,405.5a2.5,2.5,0,0,1-1.4-.21,85.86,85.86,0,0,0-11.53-2.73,88.35,88.35,0,0,0-10.78-.88,99.17,99.17,0,0,0-22.41,1.91A177.34,177.34,0,0,0,562,412.53c-6.22,2.39-12.37,5-18.42,7.75s-12.36,5.84-18.46,9c-7.45,3.81-14.89,7.63-22.38,11.34-7.07,3.49-14.19,6.87-21.49,9.86-1.45.59-2.88,1.22-4.35,1.77a2.3,2.3,0,0,1-.87.23.58.58,0,0,1-.61-.79,7.39,7.39,0,0,1,.32-1c.92-2.42,1-5.06,2-7.49a1.58,1.58,0,0,0,.09-1c-.09-.26-.17-.56.05-.75.59-.48.36-1.26.63-1.77.66-1.22.47-2.58.85-3.82a8.31,8.31,0,0,1,.7-2.23,9.72,9.72,0,0,1,2-2,11.35,11.35,0,0,1,2.56-1.57,9.27,9.27,0,0,0,1-.56c6.3-3.84,12.68-7.51,18.92-11.44q8-5.07,16.19-9.88c3.83-2.26,7.58-4.67,11.42-6.91,8-4.65,16-9.22,24.27-13.4,5.56-2.82,11.2-5.47,16.94-7.89a165.2,165.2,0,0,1,17.43-6.27,133.13,133.13,0,0,1,17.94-4,97.76,97.76,0,0,1,22.09-.75,74.66,74.66,0,0,1,9.41,1.28Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[10] ? selectedColor : 'white'}
      />
      <path className="d" d="M633.31,301a20.41,20.41,0,0,1-4.11.55,129.35,129.35,0,0,0-31.65,8.32c-4.56,1.82-9,3.8-13.46,6q-8.62,4.29-16.89,9.35c-8.61,5.26-17,11-25.09,17.07-5,3.76-10,7.67-14.88,11.63-4.62,3.72-9.13,7.58-13.66,11.42-3.09,2.61-6.08,5.34-9.16,8-3.78,3.22-7.43,6.62-11.18,9.89-2.85,2.5-5.66,5.05-8.53,7.53-.55.47-1.38.56-1.64,1.46-.08.25-.43.2-.66.06a1.34,1.34,0,0,1-.62-1.34c.2-1.8-.61-3.43-.71-5.18,0-.08-.08-.17-.06-.25.35-1.63-.64-3.14-.36-4.78a1.69,1.69,0,0,0-.28-1.12,2.13,2.13,0,0,1,.42-2.62c1.62-1.88,3.43-3.54,5.14-5.31,1.45-1.49,2.81-3.07,4.26-4.57.82-.85,1.69-1.65,2.5-2.52,3.53-3.8,7.33-7.31,10.91-11.05,5.15-5.36,10.5-10.5,15.81-15.66,3.4-3.31,6.88-6.52,10.39-9.69s7.28-6.41,11-9.55q13-11,26.89-20.79,9.78-6.85,20.06-12.74a187,187,0,0,1,33.57-15.28c4-1.33,8.08-2.47,12.17-3.52a2.52,2.52,0,0,1,1.56-.1Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[11] ? selectedColor : 'white'}
      />
      <path className="f" d="M542,148.69a2.73,2.73,0,0,1-.62,1.33q-8.37,12.12-16.23,24.61-7.51,12-14.61,24.18c-2.33,4-4.64,8-6.9,12.05-1.55,2.78-3.16,5.52-4.68,8.32-3.5,6.43-7,12.85-10.42,19.32-3.19,6-6.31,12.1-9.41,18.18q-6.22,12.21-12.22,24.54-5.31,11-10.61,22c-1.94,4.06-3.81,8.17-5.78,12.22-1.15,2.38-2.11,4.86-3.37,7.19-.72,1.32-.88,1.38-2.07.4a5.2,5.2,0,0,0-2-1.18,1.69,1.69,0,0,1-.82-.53,14.25,14.25,0,0,0-3-2.48c-.21-.13-.4-.29-.6-.44a1.21,1.21,0,0,1-.43-1.58c1.58-4.93,3.55-9.72,5.31-14.58,2.44-6.79,5-13.51,7.6-20.25q4.51-11.83,9.12-23.65c2.49-6.41,5-12.81,7.58-19.19,4.09-10.13,8.21-20.26,12.44-30.34q9.09-21.65,18.77-43c2.65-5.8,5.4-11.54,8.05-17.34a1.76,1.76,0,0,1,2-1.18c7.92.42,15.84.79,23.77,1.18a3.67,3.67,0,0,0,1.8-.14Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[12] ? selectedColor : 'white'} />

      <path className="d" d="M630.8,352.21c-2.39-.15-4.79-.37-7.18-.42s-4.79-.19-7.2-.07a103,103,0,0,0-12.74,1.4,115.64,115.64,0,0,0-17.55,4.69,156.17,156.17,0,0,0-18.79,8c-6.37,3.19-12.63,6.62-18.72,10.41-3.68,2.3-7.38,4.55-11,6.93q-10,6.54-19.79,13.47c-4.58,3.24-9.18,6.44-13.76,9.67-3.28,2.31-6.53,4.67-9.82,7s-6.41,4.54-9.73,6.61c-.45.29-.86.68-1.32,1-.66.39-1.08.2-1.11-.56,0-1.35-.38-2.71.06-4.06a2.47,2.47,0,0,0,0-1.05,2.62,2.62,0,0,1-.06-.78c.54-2.79.05-5.61.33-8.41.19-1.86.45-3.44,2.07-4.56s2.8-2.28,4.19-3.45c2.19-1.84,4.36-3.7,6.56-5.53s4.67-3.84,7-5.77c.81-.67,1.58-1.39,2.39-2.06,3-2.5,6-5,9-7.5,2.08-1.71,4.18-3.4,6.28-5.07,2.83-2.24,5.66-4.48,8.51-6.69q8-6.17,16.2-11.92c4.32-3,8.72-5.91,13.16-8.72q9.09-5.74,18.61-10.65a167.25,167.25,0,0,1,19.38-8.51,117.36,117.36,0,0,1,27.37-6.64,47,47,0,0,1,9.26-.45Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[13] ? selectedColor : 'white'}
      />
      <path className="d" d="M285.84,120.48l14.87.73c1.57.08,1.57.09,2,1.7q3.87,14.29,7.64,28.61c3.49,13.31,6.85,26.67,10.24,40,1.59,6.23,3,12.5,4.6,18.73s3.05,12.48,4.57,18.73c1.3,5.35,2.53,10.73,3.81,16.09q2.1,8.79,4.23,17.59c.95,4,1.84,7.93,2.78,11.88.82,3.44,1.67,6.88,2.48,10.33.95,4.09,1.85,8.19,2.84,12.27.35,1.44.65,2.9,1,4.34a3.55,3.55,0,0,1,.18,1.68,1.27,1.27,0,0,0,.29,1,2.35,2.35,0,0,1-.38,2.74,2.08,2.08,0,0,1-.4.32c-1.29.8-2.48,1.8-3.84,2.48a1.89,1.89,0,0,0-.62.42c-.91,1.08-2.21,1.47-3.32,2.18-.91.58-1.51.28-1.88-.84-.91-2.77-1.78-5.55-2.68-8.33q-2.46-7.64-4.94-15.29-1.74-5.4-3.49-10.81-2.46-7.58-5-15.15c-3.81-11.53-7.63-23.06-11.54-34.56q-5.43-16-11.05-31.94c-2.09-6-4.27-11.86-6.43-17.78-2.6-7.15-5.31-14.25-8-21.36-3.79-10-7.82-19.87-11.83-29.75-.75-1.85-1.56-3.67-2.32-5.52-.48-1.15-.39-1.28.78-1.22,5.12.24,10.24.5,15.36.75Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[14] ? selectedColor : 'white'}
      />
      <path className="d" d="M330.71,122.69l11.33.55c.33,0,.65,0,1,.06,1,.09,1.1.19,1.24,1.23.36,2.66.7,5.31,1,8q2.07,16.53,4.15,33.05,2.21,17.85,4.37,35.69,1,8.37,2.05,16.73,1.11,9.28,2.22,18.56c.87,7.19,1.77,14.38,2.64,21.57.65,5.36,1.26,10.72,1.9,16.08.51,4.27,1,8.54,1.57,12.8a6.55,6.55,0,0,1-.42,3,2.13,2.13,0,0,1-2.06,1.39,3.18,3.18,0,0,0-2.59,1.18,1.5,1.5,0,0,1-1,.51,2.66,2.66,0,0,0-1.26.47.8.8,0,0,1-.92.09c-.43-.23-.75-.06-1.09.27-.87.86-1.7.59-2.06-.63a23.11,23.11,0,0,1-.74-3.06c-.58-3.64-1.46-7.21-2.15-10.82q-1.19-6.25-2.44-12.51c-.73-3.64-1.51-7.28-2.25-10.93q-1.65-8.18-3.29-16.35-2.55-12.56-5.11-25.1-2.54-12.36-5.08-24.7-2-9.45-4-18.9-3.09-14.58-6.19-29.15c-1.24-5.78-2.55-11.54-3.81-17.31-.56-2.58-.7-2.37,1.7-2.26l11.22.55Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[15] ? selectedColor : 'white'}
      />
      <path className="g" d="M424.51,303.91c.14-.68.23-1.26.36-1.83,1.3-5.64,2.56-11.3,3.93-16.92,1.18-4.84,2.23-9.71,3.36-14.56q3.57-15.28,7.17-30.56c1.86-7.86,3.76-15.72,5.64-23.58,2.73-11.35,5.42-22.71,8.19-34.05,2.81-11.52,5.7-23,8.55-34.51q1.6-6.41,3.22-12.8c.38-1.49.45-1.57,1.86-1.5q12.25.59,24.5,1.2a6,6,0,0,1,.61,0c.76.11.84.24.55,1.06-.77,2.15-1.54,4.29-2.33,6.42-4,10.8-7.85,21.67-11.67,32.54-2.7,7.67-5.33,15.37-7.95,23.07-2.47,7.28-4.85,14.6-7.32,21.88-3.74,11.05-7.29,22.18-10.92,33.27-2,6.16-4,12.36-5.92,18.54s-3.94,12.55-5.92,18.82c-1.35,4.27-2.74,8.51-4.06,12.79-.51,1.67-1,3.34-1.59,5a3.25,3.25,0,0,1-.45,1c-.55.71-.93.84-1.7.47s-1.31-.71-2-1.05c-1.56-.81-3.13-1.62-4.71-2.41A2.16,2.16,0,0,1,424.51,303.91Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[16] ? selectedColor : 'white'}
      />
      <path className="h" d="M440.37,128.06l10,.49a8.06,8.06,0,0,1,1,.06c.83.11,1,.33.81,1.24-.29,1.52-.62,3-.93,4.53q-3.35,16.37-6.7,32.73-3.63,17.76-7.23,35.53c-1.39,6.88-2.72,13.77-4.09,20.66-1,5.15-2.11,10.28-3.14,15.43s-2,10.22-3,15.33c-.88,4.39-1.79,8.78-2.66,13.17-.73,3.68-1.43,7.38-2.15,11.06-.88,4.49-1.78,9-2.66,13.45-.2,1-.78,1.89-.89,3,0,.41-.5.57-.87.63a3.87,3.87,0,0,1-1.69-.14c-1.25-.39-2.51-.75-3.76-1.15a17.52,17.52,0,0,0-3.58-.74c-.9-.1-1-.33-.52-1.19a1.06,1.06,0,0,0,.14-1,3.59,3.59,0,0,1-.08-1.87c.4-3.42.72-6.86,1.22-10.26.38-2.56.76-5.12,1-7.69.25-2.85.85-5.64,1-8.5.16-2.44.6-4.85.89-7.28.26-2.07.47-4.15.72-6.22.34-2.79.7-5.57,1.05-8.36l.54-4.31q.54-4.4,1.09-8.77c.17-1.35.29-2.7.47-4,.51-3.87.83-7.76,1.39-11.61s.85-7.48,1.42-11.2c.41-2.68.62-5.4,1-8.1.48-3.86,1-7.73,1.47-11.59.33-2.61.62-5.22.94-7.83.35-2.88.73-5.76,1.08-8.64.33-2.65.63-5.31,1-8q.45-3.72.92-7.42.54-4.26,1.07-8.5c.35-2.74.69-5.49,1.05-8.23.17-1.3.24-2.62.49-3.91s.27-1.32,1.45-1.28c1.38,0,2.76.13,4.14.2Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[17] ? selectedColor : 'white'}
      />
      <path className="d" d="M369.1,124.57c3.49.17,7,.33,10.48.51,1.28.07,1.36.15,1.4,1.56,0,1.62,0,3.25.06,4.88q0,3.22.11,6.45c0,1.37,0,2.73.05,4.09.1,3.16.05,6.33.09,9.49s.18,6.59.17,9.89c0,1.89.06,3.78.07,5.67,0,3.07,0,6.15.16,9.22.1,1.49.06,3,.05,4.48-.05,3.48.14,6.94.08,10.42-.06,3.12.11,6.24.19,9.36,0,1.45,0,2.89,0,4.35.12,3.16,0,6.33.12,9.49.12,3.33,0,6.68.16,10,.07,1.76,0,3.52,0,5.27,0,2.07.08,4.13.12,6.2,0,1.36.05,2.73,0,4.08,0,1.89.13,3.78.06,5.67-.06,1.67.08,3.34.14,5s0,3.17,0,4.74c-.05,3.43.12,6.85.11,10.28,0,3,0,6,.16,9,.11,2.37,0,4.75,0,7.12,0,.8-.26,1.16-.95,1.31-2,.43-4,.85-6.07,1.22-1,.19-2.1.12-3.15.4s-1.49-.35-1.63-1.45c-.41-3.4-.67-6.82-.94-10.23-.21-2.72-.5-5.43-.73-8.14-.45-5.3-.85-10.6-1.36-15.89-.28-3-.61-5.94-.86-8.91q-.81-9.59-1.65-19.17c-.33-3.76-.71-7.52-1-11.28s-.64-7.79-1-11.69c-.45-5.07-.92-10.14-1.38-15.22L360,157.92l-2.82-32c0-.18,0-.35,0-.53,0-1.09.2-1.35,1.21-1.31,2.68.11,5.36.26,8.05.39Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[18] ? selectedColor : 'white'}
      />
      <path className="d" d="M404.59,126.31l9.51.46,1,.07a.84.84,0,0,1,.86,1,49.22,49.22,0,0,1-.36,5,6.91,6.91,0,0,0-.15.91c-.08,2.86-.51,5.69-.74,8.54-.2,2.4-.3,4.83-.73,7.21a2.33,2.33,0,0,0-.06.53c-.06,3.17-.53,6.31-.79,9.46-.21,2.45-.29,4.92-.74,7.35a2.45,2.45,0,0,0,0,.39c-.05,2.86-.53,5.69-.72,8.54s-.42,5.52-.82,8.26c0,.14,0,.27,0,.4-.06,3.17-.52,6.31-.79,9.46-.2,2.41-.34,4.82-.72,7.22-.28,1.77-.35,3.59-.49,5.38-.23,2.93-.54,5.86-.78,8.8s-.42,5.7-.83,8.53a6.87,6.87,0,0,0-.08.78c-.2,5-1,9.89-1.29,14.85-.24,3.64-.64,7.27-1,10.9-.4,4.15-.71,8.32-1.11,12.47-.35,3.63-.7,7.26-1,10.89-.25,2.71-.52,5.43-.83,8.13-.12,1-.22,1.19-1.15,1-1.72-.28-3.48.24-5.2-.31-.63-.2-1.37.18-2.06,0s-1.33-.67-2.1-.25c-.19.11-.38-.11-.47-.32a5.28,5.28,0,0,1-.63-2.23c.23-4.53.19-9.06.32-13.59a63.06,63.06,0,0,0,0-6.45c-.12-1.7.17-3.43.15-5.14,0-3.79.25-7.57.28-11.35,0-4.83.13-9.67.22-14.5q.15-8,.28-16.09c0-2.46.16-4.92.16-7.38,0-5.15.21-10.29.23-15.43,0-2.65.26-5.29.16-7.91-.15-3.66.38-7.3.12-10.95a1.13,1.13,0,0,1,0-.26c.29-4,.15-7.92.27-11.88.11-3.51.25-7,.12-10.54,0-.79,0-1.59.08-2.38.26-4.44.13-8.88.33-13.32.08-1.79,0-3.61.08-5.41.08-1.36.2-1.48,1.49-1.42C397.85,126,401.22,126.14,404.59,126.31Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[19] ? selectedColor : 'white'}
      />
      <path className="i" d="M305.49,444.27" transform="translate(-82.29 -111.09)"

      />
      <path className="g" d="M458.55,343.11s94.57-125.43,151-159.87l10-5.32,6.4,11.45S519.64,271.9,464.3,353Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[20] ? selectedColor : 'white'}
      />
      <path className="a" d="M106.24,448h-14V127.19l14-.92Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[21] ? selectedColor : 'white'}
      />
      <path className="j" d="M273.49,450.55s99-385.8,238,12v12.7Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[22] ? selectedColor : 'white'}
      />
      <path className="j" d="M508.85,466.12v9.15l-23.52-1.76-57.8-4.33-69-5.16-64.16-4.8-23.5-1.76s.91-2.55,2.67-6.91c.18-.45.38-.92.57-1.41.1-.22.19-.44.28-.67,1.82-4.39,4.31-10.14,7.46-16.73q.8-1.68,1.65-3.42c2.14-4.35,4.53-9,7.18-13.82,32.36-59,102-142.33,191.34,2.24.7,1.12,1.39,2.26,2.09,3.41q2.21,3.64,4.43,7.48,4.29,7.42,8.66,15.56l0,.07q3.93,7.32,7.89,15.25Q507,462.25,508.85,466.12Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[23] ? selectedColor : 'white'}

      />
      <path className="j" d="M487.65,477.44c-7.78-1.5-15.62-2.77-23.52-3.86q-18.24-2.52-36.6-4.4l-69-5.16q-20.24-1-40.48-1.5-12.63-.31-25.28-.51l1.6-2.79c.51-.87,1-1.74,1.55-2.62.68-1.16,1.39-2.33,2.11-3.5l.58-1c.68-1.1,1.37-2.19,2.07-3.29,33.78-52.9,94.09-106.43,169.6,1.3q3.45,4.9,6.93,10.27c.49.75,1,1.51,1.46,2.27q3,4.73,6.07,9.79l.61,1C486.1,474.8,486.88,476.11,487.65,477.44Z" transform="translate(-82.29 -111.09)"
        onClick={handleClick}
        style={{ pointerEvents: 'all' }}
        fill={isClicked[24] ? selectedColor : 'white'}
      />
      <path d="M632.19,175.92c-26.8-9.52-53.27-20.2-80.47-28.37a810.31,810.31,0,0,0-212.48-33.63c-56.6-1.49-113.23-2-169.86-2.66-28.83-.33-57.68-.06-87.09-.06V464.37h15.2c65.09.05,130.2-.92,195.26.12q12.64.21,25.28.52,20.24.51,40.48,1.51c23.05,1.14,46.09,2.82,69,5.19q18.35,1.92,36.6,4.44Q476,477.79,487.65,480c48.72,9.38,94.86,27.15,134.91,59.21,5.94,4.75,11.8,9.64,18.43,15.1V193.81c0-.66,1-.56,1,.08V555.15l.67.56c.5-3.69.84-5,.84-6.36q.11-178.82.34-357.63C643.87,182.52,639.6,178.55,632.19,175.92ZM106.24,450.36h-14V127.3l14-.93Zm525.24,59.58c0,4.51-.83,9-1.33,14.08-16.59-9.38-32-19.23-48.34-27.13a403.14,403.14,0,0,0-70.32-26.11l-2.64-.72Q497,466.94,485,464.45c-2.59-.54-5.18-1.05-7.78-1.55-4.08-.77-8.16-1.5-12.26-2.16a564.76,564.76,0,0,0-67-6.75c-32.41-1.39-64.83-2.2-97.27-2.74l-2.8-.05q-11.75-.19-23.49-.34H274l-1.58,0c-42.5-.55-85-.79-127.49-1.46-8.52-.14-17,0-26.62,0V127.18h4.05l53.44,0h36.55q20.91,0,41.79,0h18.08l27.47,0h4.1q7.29,0,14.57.12,13.17.18,26.3.72c4.25.16,8.48.36,12.72.58q11.82.61,23.61,1.56c4,.3,7.93.65,11.89,1q11.29,1,22.54,2.4,5.93.7,11.82,1.51,11.57,1.56,23.08,3.48c4.37.73,8.74,1.49,13.1,2.31q12.65,2.33,25.22,5.13,8.9,2,17.75,4.22,8.19,2,16.36,4.32,6.53,1.81,13,3.8c8.55,2.6,17,5.37,25.51,8.23,11.83,4,23.6,8.16,35.39,12.26,8,2.77,15.93,5.52,23.92,8.17a25,25,0,0,1,5.33,2.39c3.85,2.41,5.95,6,5.94,12.11,0,3.36,0,6.7,0,10.05l0,11.91-.06,26.4,0,18q0,17.43,0,34.88,0,8.58,0,17.15,0,8.16,0,16.33,0,17.29,0,34.57l0,35.11q0,9.36,0,18.7l0,28.85Q631.52,481.67,631.48,509.94Z" transform="translate(-82.29 -111.09)"
        fill={selectedStroke}
      />
    </svg>
  )
}

export default Flag12




/*  
    .a,.j{fill:#fff;}.b,.c,.d,.e,.f,.g,.h,.i{fill:none;}.b,.c,.d,.e,.f,.g,.h,.j{stroke:#000;stroke-miterlimit:10;}.b{stroke-width:3.05px;}.c{stroke-width:3.07px;}.d,.j{stroke-width:3px;}.e{stroke-width:2.99px;}.f{stroke-width:3.04px;}.g{stroke-width:3.07px;}.h{stroke-width:3.05px;}
*/