
import React, { useState, useEffect } from "react";
import MobileHeader from './MobileHeader';
import DesktopHeader from './DesktopHeader';

const Header = () => {
    const screenWidthChange = 768;
    const [isMobile, setIsMobile] = useState(window.innerWidth <= screenWidthChange);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= screenWidthChange);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <header>
            {!isMobile ? (
                <DesktopHeader />
            ) : <MobileHeader />}
        </header>
    )
}

export default Header