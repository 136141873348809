const colors = [
    {
        id: 1,
        color: '#000000'  // Black
    },
    {
        id: 2,
        color: '#ffffff'  // White
    },


    {
        id: 3,
        color: '#A9A9A9'
    },

    {
        id: 4,
        color: '#3C4142'
    },

    // Blues
    {
        id: 5,
        color: '#0C2545'  // Blue
    },
    {
        id: 6,
        color: '#355070'  // Blue
    },
    {
        id: 7,
        color: '#000080'  // Blue
    },
    {
        id: 8,
        color: '#0000FF'  // Blue
    },
    {
        id: 9,
        color: '#2a598f'  // Blue
    },

    {
        id: 10,
        color: '#4682B4'  // Blue
    },
    {
        id: 11,
        color: '#00FFFF'  // Blue
    },
    {
        id: 12,
        color: '#008B8B'  // Blue. Put at the end of blue
    },
    {
        id: 13,
        color: '#2F4F4F'  // Green
    },
    {
        id: 14,
        color: '#556B2F'  // Green
    },
    {
        id: 15,
        color: '#2E8B57'  // Green
    },

    {
        id: 16,
        color: '#008000'  // Green
    },
    {
        id: 17,
        color: '#20961c'  // Green
    },
    {
        id: 18,
        color: '#32CD32'  // Green
    },
    {
        id: 19,
        color: '#4B0082'  // Purple
    },
    {
        id: 20,
        color: '#800080'  // Purple
    },
    {
        id: 21,
        color: '#6d597a'  // Purple
    },
    {
        id: 22,
        color: '#8A2BE2'  // Purple
    },
    {
        id: 23,
        color: '#9370DB'  // Purple
    },
    {
        id: 24,
        color: '#b56576'  // Doesn't fit one super well. Put it at the end of the purples
    },
    {
        id: 25,
        color: '#e56b6f'  // Pink
    },
    {
        id: 26,
        color: '#FF1493'  // Pink
    },
    {
        id: 27,
        color: '#FF00FF'  // Pink
    },
    {
        id: 28,
        color: '#FFC0CB'  // Pink
    },
    {
        id: 29,
        color: '#FA8072'  // Pink
    },
    {
        id: 30,
        color: '#C64347'  // Red
    },
    {
        id: 31,
        color: '#FF0000'  // Red
    },
    {
        id: 32,
        color: '#D21404'  // Red
    },
    {
        id: 33,
        color: '#800000'  // Red
    },

    {
        id: 34,
        color: '#7E2811'  // Red
    },
    {
        id: 35,
        color: '#FF4500'  // Red
    },
    {
        id: 36,
        color: '#F04A00'  // Orange
    },
    {
        id: 37,
        color: '#FFA500'  // Orange
    },
    {
        id: 38,
        color: '#FC6A03'  // Orange
    },
    {
        id: 39,
        color: '#ED8203'  // Orange
    },
    {
        id: 40,
        color: '#DD571C'  // Orange
    },
    {
        id: 41,
        color: '#D2691E'  // Orange
    },
    {
        id: 42,
        color: '#8B4513'  // Brown
    },
    {
        id: 43,
        color: '#FFFF00'  // Yellow
    },
    {
        id: 44,
        color: '#DFFF00'  // Yellow
    },
    {
        id: 45,
        color: '#FEDC56'  // Yellow
    },
    {
        id: 46,
        color: '#F4C430'  // Yellow
    },
    {
        id: 47,
        color: '#DBA858'  // Yellow
    },
    {
        id: 48,
        color: '#DAA520'  // Yellow
    },
    {
        id: 49,
        color: '#eaac8b'
    },
    {
        id: 50,
        color: '#fbdc93'  // Yellow
    },
];

export default colors