import React from 'react'
import Ad from '../Ad';
import ArticlesGrid from '../ArticlesGrid';

const ArticlesPage = () => {
    return (
        <div>
            <ArticlesGrid />
            <Ad />
        </div>
    )
}

export default ArticlesPage