import React, { useState, useEffect } from 'react'
import Tile from './Tile';
import './styles/ticTacToe.css';

function TicTacToeBoard({ mode, setSingleGameMode, setTwoPlayerGameMode }) {
    const [player, setPlayer] = useState('Trombone');
    const initialBoardState = Array(9).fill(null);
    const [board, setBoard] = useState(initialBoardState);
    const [win, setWin] = useState(null)
    const [winStreak, setWinStreak] = useState(null);
    const winConditions = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [2, 4, 6],
        [0, 4, 8]
    ];

    const playButtonSound = () => {
      const audio = new Audio('/sounds/button-sound.mp3');
      audio.volume = 0.2;
      audio.play();
    }

    const isDisabled = mode === 1 && player === 'Drummer';


    useEffect(() => {
        if (mode === 1 && player === 'Drummer') {
            const emptySquares = board.reduce((acc, val, index) => {
                if (val === null) {
                    acc.push(index);
                }
                return acc;
            }, []);

            if (emptySquares.length > 0 && !win) {
                let delay;
                const delayedMove = () => {
                    makeSmartComputerMove(board);
                };

                delay = setTimeout(delayedMove, 1000);

                return () => clearTimeout(delay);
            }
        }
    }, [player, board, win]);


    const playerMove = (index) => {
        if (win || board[index] !== null) {
            return;
        }
        const newBoard = [...board];
        newBoard[index] = player;
        setBoard(newBoard);
        const winner = checkWin(newBoard, player);
        if (winner) {
            setWin(winner);
            return;
        }

        setPlayer(player === 'Trombone' ? 'Drummer' : 'Trombone');

    };


    const checkWin = (currentBoard, currentPlayer) => {
        let isBoardFull = true;
        for (let i = 0; i < winConditions.length; i++) {
            const [a, b, c] = winConditions[i];
            if (
                currentBoard[a] === currentPlayer &&
                currentBoard[b] === currentPlayer &&
                currentBoard[c] === currentPlayer
            ) {

                setWinStreak(winConditions[i]);
                return currentPlayer;

            }

            if (currentBoard[a] === null || currentBoard[b] === null || currentBoard[c] === null) {
                isBoardFull = false;
            }
        }

        if (isBoardFull) {
            return "Tie";
        }
        setWinStreak(null);
        return null;

    };

    const makeSmartComputerMove = (currentBoard) => {
        const emptySquares = currentBoard.reduce((acc, val, index) => {
            if (val === null) {
                acc.push(index);
            }
            return acc;
        }, []);
        for (let i = 0; i < emptySquares.length; i++) {
            const testBoard = [...currentBoard];
            testBoard[emptySquares[i]] = 'Drummer';
            if (checkWin(testBoard, 'Drummer')) {
                playerMove(emptySquares[i]);
                return;
            }
        }
        for (let i = 0; i < emptySquares.length; i++) {
            const testBoard = [...currentBoard];
            testBoard[emptySquares[i]] = 'Trombone';
            if (checkWin(testBoard, 'Trombone')) {
                playerMove(emptySquares[i]);
                return;
            }
        }
        const randomIndex = Math.floor(Math.random() * emptySquares.length);
        playerMove(emptySquares[randomIndex]);
    };



    const reset = () => {
      playButtonSound();  
      setBoard(initialBoardState);
      setPlayer('Trombone');
      setWin(null);
      setWinStreak(null);
    };


    const switchToSingle = () => {
        if (mode === 2) {
            reset()
            setSingleGameMode()
        }
    }

    const switchToDouble = () => {
        if (mode === 1) {
            reset()
            setTwoPlayerGameMode()
        }
    }

    return (
        <div className='container'>


            <div className='windisplay'>


                {win ? (
                    win === "Tie" ? (
                        <h3 className='animate__animated animate__fadeIn'>It's a Tie!</h3>
                    ) : (
                        <h3 className='animate__animated animate__fadeIn'>{win} wins</h3>
                    )
                ) : (
                    <h3 className='animate__animated animate__fadeIn'>{player}'s turn</h3>
                )}

            </div>
            <div id='board-outer'>
                {win ? (

                    <div>

                    </div>



                ) : (
                    <> </>
                )}
                <div id='board-body'>



                    {board.map((value, index) => {
                        let isWinningSquare = false;
                        if (winStreak && winStreak.includes(index)) {
                            isWinningSquare = true;
                        }
                        return <Tile key={index} value={value} player={player} onClick={() => playerMove(index)} isWinningSquare={isWinningSquare}
                            disabled={isDisabled}
                        />
                    })}

                    <div className={`animate__animated animate__fadeIn ${JSON.stringify(winStreak) === JSON.stringify(winConditions[0]) ? 'win-streak-1' : JSON.stringify(winStreak) === JSON.stringify(winConditions[1]) ? 'win-streak-2' : JSON.stringify(winStreak) === JSON.stringify(winConditions[2]) ? 'win-streak-3' : JSON.stringify(winStreak) === JSON.stringify(winConditions[3]) ? 'win-streak-4' : JSON.stringify(winStreak) === JSON.stringify(winConditions[4]) ? 'win-streak-5' : JSON.stringify(winStreak) === JSON.stringify(winConditions[5]) ? 'win-streak-6' : JSON.stringify(winStreak) === JSON.stringify(winConditions[6]) ? 'win-streak-7' : JSON.stringify(winStreak) === JSON.stringify(winConditions[7]) ? 'win-streak-8' : ''}`}>
                    </div>

                </div>

            </div>

            {
                !win ? (
                    <div>
                        <button onClick={reset} className='reset-button'>Reset</button>
                        <div className='modes-change'>
                            <div className={`mode-btn-2 ${mode === 1 ? 'active-mode-btn' : ''}`} onClick={switchToSingle}>
                                <img src={process.env.PUBLIC_URL + '/images/single-player.png'} height='15px'></img>
                                <p>Single Player</p>
                            </div>
                            <div className={`mode-btn-2 ${mode === 2 ? 'active-mode-btn' : ''}`} onClick={switchToDouble}>
                                <img src={process.env.PUBLIC_URL + '/images/double-player.png'} height='15px'></img>
                                <p>Two Player</p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <button onClick={reset} className='reset-button'>Play Again</button>
                        <div className='modes-change'>
                            <div className={`mode-btn-2 ${mode === 1 ? 'active-mode-btn' : ''}`} onClick={switchToSingle}>
                                <img src={process.env.PUBLIC_URL + '/images/single-player.png'} height='15px'></img>
                                <p>Single Player</p>
                            </div>
                            <div className={`mode-btn-2 ${mode === 2 ? 'active-mode-btn' : ''}`} onClick={switchToDouble}>
                                <img src={process.env.PUBLIC_URL + '/images/double-player.png'} height='15px'></img>
                                <p>Two Player</p>
                            </div>
                        </div>
                    </div>
                )
            }



        </div >
    )
}

export default TicTacToeBoard













    
   
  
   
  
   
  
   
  
 

 
  
   
   
  
  
   
 

  
   
    
 
   
   
  
  
   
    
  
   
    