import React from 'react'
import './styles/home.css';

const Photos = () => {
    return (
        <div id='photos-homepage'>
            <div id='photo-line'></div>
            <div id='phto-title-top'>
                <img src={process.env.PUBLIC_URL + 'images/camera-icon-blue.png'} className='camera-icon'></img>
                <h2>SEND US YOUR PHOTOS</h2>
            </div>

            <div id='photos-content'>
                <div id='photos-content-left'>
                    <img src={process.env.PUBLIC_URL + 'images/photos-image.png'} className='photos-image'></img>
                </div>
                <div id='photos-content-right'>
                    <h3>Say Cheese!</h3>
                    <p>
                        Welcome to the heart of the marching band experience, where the vibrant rhythm of music meets the electrifying energy of fans! Our marching band events are not just about the stunning performances on the field but also the unforgettable moments in the stands. We encourage you to unleash your inner photographer and capture the essence of the event. Whether it's the fans exuberantly dressed in wild and colorful costumes or the sheer excitement on their faces, there's always something unique to photograph. Share your coolest snapshots with us on our Between Bands Facebook page for a chance to have your photo featured on our platform. We believe that the spirit of the marching band community can be perfectly captured through your lens, so grab your camera, snap some shots, and let's celebrate the magic of these events together!
                    </p>
                    <a style={{ textDecoration: 'none', width: '100%' }} id="pht-btn-1" href='https://www.facebook.com/profile.php?id=61565144170427' target='_blank'>
                        <button id='photos-button'>
                            Facebook Page
                        </button>
                    </a>

                </div>


            </div>

            <a id="pht-btn-2" style={{ textDecoration: 'none', width: '100%' }} href='https://www.facebook.com/profile.php?id=61565144170427' target='_blank'>
                <button id='photos-button-2'>Facebook Page</button>
            </a>

            <div id='photos-line-2'></div>
        </div>
    )
}

export default Photos
