import React, { useState, useEffect } from 'react';
import "./../styles/home.css";

function withLoading(WrappedComponent) {
  return function(props) {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      setTimeout(() => {
        setIsLoading(false);
      }, 1250);
      window.scrollTo(0, 0);
    }, []);

    if (isLoading) {
      return (
        <div className="loading-graphic">
          <div className="loading-circle"></div>
        </div>
      );
    } else {
      return <WrappedComponent {...props} />;
    }
  };
}

export default withLoading;