import React from 'react'
import './styles/home.css';
import { Link } from 'react-router-dom'

const AboutHome = () => {
    return (
        <div id='jokes-main'>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'center' }}>
                <img id='b-b-logo-title' src={process.env.PUBLIC_URL + 'images/logo.png'}></img>
                <h2 id='b-bands'>BETWEEN BANDS</h2>
            </div>
            <h3 id='marching-band-jokes-2'>Who Are We?</h3>
            <div id='jokes'>
                <div id='jokes-img' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/ad-square-background.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div id='between-bands-summary'>
                        <img id='jokes-mobile-logo' src={process.env.PUBLIC_URL + 'images/logo.png'}></img>
                    </div>

                </div>
                <div id='jokes-img-logo'>
                    <img id='between-bands-logo' src={process.env.PUBLIC_URL + 'images/logo.png'}></img>
                </div>


                <div id='jokes-description'>
                    <h3 id='marching-band-jokes'>Who Are We?</h3>
                    <p>
                        We are Between Bands, your Edutainment for today. Brought to you by Scholarship Auditions, our aim was to bring to you a platform for you to spend your time while you are waiting between marching band sets at todays contest. Read our informative articles about marching band and leave here having learned something. Play our games by yourself or with fellow audience members. Or enjoy our relaxing activities. Enjoy the contest, but also enjoy the down time with us on Between Bands. Click the button below to learn more about us and make sure to look into our amazing partners below.
                    </p>

                    <Link style={{ textDecoration: 'none' }} to='/about'>
                        <button id='jokes-btn'>Learn More</button>
                    </Link>


                </div>
            </div>
            <button id='jokes-btn-2'>Learn More</button>
        </div>
    )
}

export default AboutHome