import React, { useState } from 'react'
import './../styles/ticTacToe.css';
import TicTacToeBoard from '../TicTacToeBoard';

const TicTacToe = () => {
    const [optionsDisplay, toggleOptionsDisplay] = useState(true);

    const hideDisplayOptions = () => {
        toggleOptionsDisplay(false);
    }

    const [mode, gameMode] = useState(null)

    const playButtonSound = () => {
        const audio = new Audio('/sounds/button-sound.mp3');
        audio.volume = 0.2;
        audio.play();
    }

    const setSingleGameMode = () => {
        playButtonSound();
        hideDisplayOptions();
        gameMode(1)
    }

    const setTwoPlayerGameMode = () => {
        playButtonSound()
        hideDisplayOptions();
        gameMode(2)
    }



    return (
        <div id='tic-tac-toe-main' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/ttt-bg.jpeg)` }} >
            {optionsDisplay ? (
                <div id='modes'>
                    <h2>Select a Game Mode</h2>
                    <div className='mode-section'>
                        <div className='mode-btn' onClick={setSingleGameMode}>
                            <img src={process.env.PUBLIC_URL + '/images/single-player.png'} height='15px'></img>
                            <p>Single Player</p>
                        </div>
                        <div className='mode-btn' onClick={setTwoPlayerGameMode}>
                            <img src={process.env.PUBLIC_URL + '/images/double-player.png'} height='15px'></img>
                            <p>Two Player</p>
                        </div>
                    </div>
                </div>
            ) : (
                <TicTacToeBoard setSingleGameMode={setSingleGameMode} setTwoPlayerGameMode={setTwoPlayerGameMode} mode={mode} />
            )}

        </div>
    )

}

export default TicTacToe 