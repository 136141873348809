import React from 'react'
import ArticleSquares from './ArticleSquares'
import articles from './articleData'
import './styles/home.css';

const ArticlesGrid = () => {
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2em' }}>
                <img className="article-icon" src={process.env.PUBLIC_URL + 'images/article-icon.png'}></img>
                <h2 id='articles-header'>ARTICLES</h2>
            </div>
            <div>
                <div id='article-header-line'></div>
            </div>
            <div className='article-grid'>
                {articles.map((article) => (
                    <ArticleSquares key={article.id} article={article} />
                ))}
            </div>
        </div>
    )
}

export default ArticlesGrid