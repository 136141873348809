import IntroCarousel from "../IntroCarousel";
import "./../styles/home.css";
import Ad from "../Ad";
import GameHomepage from "../GameHomepage";
import Photos from "../Photos";
import AboutHome from "../AboutHome";
import ActivitiesHome from "../ActivitiesHome";
import ArticlesGrid from "../ArticlesGrid";

const Home = () => {


    return (
        <div>
            <IntroCarousel />
            <ArticlesGrid />
            <div id='entertainment'>
                <div style={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
                    <img id='entertainment-icon' src={process.env.PUBLIC_URL + 'images/entertainment-icon.png'} alt='entertainment icon'></img>
                    <h2 id='entertainment-h'>ENTERTAINMENT</h2>
                </div>

                <div id='entertainment-line'></div>


                <p>Don't feel like reading? No problem! In our Entertainment section, you'll discover a treasure trove of engaging activities without the need for articles. Enjoy captivating games like our unique trombones and drums-inspired Tic-Tac-Toe or test your marching band expertise with our crossword puzzles and trivia game. Get creative with our coloring book activity and embark on a digital scavenger hunt adventure. Plus, don't forget to feature your own event photos in our lively photo section. We've got plenty to keep you entertained </p>

            </div>


            <GameHomepage />


            <ActivitiesHome />

            <Photos />

            <AboutHome />

            <Ad />
        </div>
    )
}

export default Home
