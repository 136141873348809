import React from 'react'

const Footer = () => {
    return (
        <footer>

            <div className='footer-top'>

                <div id='socials'>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2em' }}>
                        <img id='logo-footer' src={process.env.PUBLIC_URL + '/images/logo-white.png'} width='40px'></img>
                        <h2 id='title-footer'>Between Bands</h2>
                    </div>

                    <h3>Follow Us</h3>
                    <div className='footer-line'></div>
                    <div className='social-icons'>
                        <a href='https://www.facebook.com/profile.php?id=61565144170427' target='_blank'><i className="fa fa-facebook social-icon"></i></a>
                        <a href='https://twitter.com/Accoladi' target='_blank'>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 545.29 512.85"
                                className='x-icon'
                            >
                                <defs>
                                    <style>

                                    </style>
                                </defs>
                                <path className="x-icon-fill" d="M184.21,49.88c25.15,0,50.3.1,75.44-.09,4.15,0,6.63,1.38,9.06,4.63q65.75,88.14,131.6,176.19c2.54,3.41,3.77,3.11,6.41.25Q458.25,175,510,119.22q30.18-32.61,60.3-65.3a11.5,11.5,0,0,1,9.5-4.13c12.64.24,25.29.07,37.93.12,1.31,0,3.12-.63,3.77,1,.54,1.31-.94,2.2-1.76,3.09q-42.78,46.22-85.59,92.4-52.81,57-105.67,114c-2.6,2.79-2.34,4.66-.2,7.51q52.23,69.57,104.3,139.27Q588.7,482.2,644.83,557.27c.9,1.21,2.65,2.45,2.06,3.92-.75,1.85-3,1.13-4.56,1.13q-76.5,0-153,.08a8.68,8.68,0,0,1-7.73-3.95q-72-96.38-144.06-192.65c-3-4-2.89-3.92-6.26-.29q-39.49,42.64-79,85.27-49.94,53.93-99.8,107.94a10.32,10.32,0,0,1-8.42,3.69c-12.22-.16-24.44,0-36.66-.09-1.9,0-4.67.91-5.49-.91-.93-2.09,1.78-3.48,3.1-4.92q23.12-25.11,46.35-50.09Q212,440.88,272.68,375.34c12.21-13.19,24.37-26.42,36.74-39.46,2.51-2.64,2.72-4.29.46-7.3q-68.33-90.94-136.42-182-34.2-45.7-68.36-91.45c-1-1.28-3-2.55-2.18-4.21.93-1.88,3.29-.95,5-.95Q146.08,49.83,184.21,49.88Zm-10,36.56c1.42,2,2.19,3.17,3,4.28q54.74,73.22,109.49,146.42Q393.06,379.31,499.42,521.5c2.33,3.13,4.72,4.47,8.69,4.43,20.37-.2,40.73-.08,61.1-.12,1.77,0,4.36.91,5.15-.57,1-1.89-1.68-3.18-2.59-4.82a35.05,35.05,0,0,0-2.71-3.76Q493.24,415.29,417.4,313.93q-83.79-112-167.54-224c-2-2.7-4.21-3.57-7.43-3.56-21.07.11-42.15.06-63.22.06Z" transform="translate(-101.71 -49.69)" /></svg>
                        </a>

                        <a href='https://www.instagram.com/scholarshipauditions/' target='_blank'><i className="fa fa-instagram social-icon"></i></a>

                        <a href='https://www.linkedin.com/company/scholarship-auditions/mycompany/' target='_blank'><i className="fa fa-linkedin social-icon"></i></a>
                    </div>

                </div>
                <div id='our-sites'>
                    <h3>Our Sites</h3>
                    <a href='https://www.scholarshipauditions.com/index.html' target='_blank' style={{ textDecoration: 'none' }}>
                        <p>ScholarshipAuditions</p>
                    </a>
                    <a style={{ textDecoration: 'none' }} href='https://www.nationalscholasticmusiciansawards.com/home' target='_blank'><p>National Scholastic Musicians Awards</p></a>
                    <a style={{ textDecoration: 'none' }} href='https://www.nationalscholasticjuniormusiciansawards.com/home' target='_blank'><p>National Scholastic Junior Musicians Awards</p></a>
                    <a style={{ textDecoration: 'none' }} href='https://www.mymusicfuture.com/' target='_blank'><p>My Music Future</p></a>
                    <a style={{ textDecoration: 'none' }} href='https://www.accoladi.com/' target='_blank'><p>Accoladi</p></a>

                </div>
            </div>

            <div className='image-banner'>


                <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/photo-1.jpg)` }} className='image-banner-img'></div>
                <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/photo-2.jpg)` }} className='image-banner-img'>
                </div>
                <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/photo-3.jpg)` }} className='image-banner-img'>
                </div>
                <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/photo-4.jpg)` }} className='image-banner-img'>
                </div>
                <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/photo-5.jpg)` }} className='image-banner-img'></div>
                <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/photo-6.jpg)` }} className='image-banner-img'></div>
            </div>
            <div className='footer-bottom'>
                <p>&copy; Between Bands 2024</p>
            </div>
        </footer>
    )
}

export default Footer
