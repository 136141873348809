import React from 'react'
import './styles/ad.css';

const Ad = () => {


    return (
        <div className='advertisement'>




            <div id='ad-line-top'></div>

            <div id='our-partners-title' >
                <img className='partners-icon' src={process.env.PUBLIC_URL + 'images/partners-icon-white.png'}></img>
                <h2 id='Ourpartners'>OUR PARTNERS</h2>
            </div>

            <div id='ad-line'></div>


            <div className='ad'>
                <div className='ad-square' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/ad-square-background.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className='ad-square-content'>
                        <h3>Accoladi</h3>
                        <img id='accoladi' className='ad-logo' src={process.env.PUBLIC_URL + 'images/scholarship-auditions.png'}></img>
                        <a style={{ textDecoration: 'none' }} href='https://www.accoladi.com/' target='_blank'>
                            <p>-VISIT SITE</p>
                        </a>
                    </div>


                </div>
                <div className='ad-square' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/ad-square-background.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className='ad-square-content'>
                        <h3>My Music Future</h3>
                        <img id='my-music-ftr' className='ad-logo' src={process.env.PUBLIC_URL + 'images/my-music-future-logo.png'} ></img>
                        <a style={{ textDecoration: 'none' }} href='https://www.mymusicfuture.com/' target='_blank'>
                            <p>-VISIT SITE</p>
                        </a>
                    </div>
                </div>
                <div className='ad-square' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/ad-square-background.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className='ad-square-content'>
                        <div>
                            <h3>NSMA</h3>
                            <p>National Scholastic Musician Awards</p>
                        </div>

                        <img id='ncma' className='ad-logo' src={process.env.PUBLIC_URL + 'images/NCMA.png'}></img>
                        <a style={{ textDecoration: 'none' }} href='https://www.nationalscholasticmusiciansawards.com/home' target='_blank'>
                            <p>-VISIT SITE</p>
                        </a>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Ad
