import React, { useState } from 'react'

const Flag1 = ({ selectedColor, onPathClick, selectedStroke }) => {


    const [isClicked, setIsClicked] = useState(new Array(12).fill(false));

    const handleClick = (event, index) => {
        onPathClick(event);


        setIsClicked((prevIsClicked) =>
            prevIsClicked.map((clicked, i) => (i === index ? !clicked : clicked))
        );
    };

    return (

        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 561.55 441.48"
            className='svg-temp'
        >
            <defs>
                <style>
                    {
                        `
      .c{stroke:${selectedStroke};stroke-width:3.02px;}.c,.d,.e{stroke-miterlimit:10;}.d,.e{${selectedStroke}}.d{stroke-width:3px;}.e{stroke-width:3.01px;}
        `
                    }

                </style>
            </defs>
            <path
                className="a"
                d="M122.17,466.23h-14V145.44l14-.92Z" transform="translate(-98.22 -129.35)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[0] ? selectedColor : 'white'}
            />
            <path className="b" d="M353.29,142.29l-2.46,3.48,4.32.15Z" transform="translate(-98.22 -129.35)"

            />
            <path className="b" d="M532.21,491.27l1.19-3.11-3.26-.92Z" transform="translate(-98.22 -129.35)"

            />

            <path className="a" d="M564,408.32l83.49,10q.08-70.05.18-140.08l-32.28-3.84Z" transform="translate(-98.22 -129.35)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[1] ? selectedColor : 'white'}
            />

            <path className="a" d="M615.42,274.38l32.28,3.84q.06-29.57.13-59.12c0-6.39-2.3-10-6.51-12.37l-.61,1.74Z" transform="translate(-98.22 -129.35)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[2] ? selectedColor : 'white'}
            />
            <polygon className="a" points="189.29 105.98 98.04 235.02 386.61 269.51 310.14 120.37 189.29 105.98"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[3] ? selectedColor : 'white'}
            />
            <path className="a" d="M615.42,274.38l25.29-65.91.61-1.74a26.71,26.71,0,0,0-4.75-2c-32.63-10.73-64.79-23.08-97.85-32.23C478.57,155.83,417.2,148,355.15,145.92l53.22,103.8Z" transform="translate(-98.22 -129.35)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[4] ? selectedColor : 'white'}
            />
            <polygon className="a" points="310.14 120.37 386.61 269.51 465.81 278.97 517.2 145.03 310.14 120.37"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[5] ? selectedColor : 'white'}
            />
            <path className="a" d="M287.51,235.32,134.29,217.08V357l62,7.41Z" transform="translate(-98.22 -129.35)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[6] ? selectedColor : 'white'}
            />
            <path className="a" d="M484.83,398.85l45.31,88.39,3.26.92L564,408.32Z" transform="translate(-98.22 -129.35)"

            />
            <path className="a" d="M196.27,364.37l-62,87.66v13.19c9.57,0,18.09-.12,26.61,0,84.33,1.32,168.73,1,253,4.59,39.46,1.69,78.47,7,116.28,17.41l-45.31-88.39Z" transform="translate(-98.22 -129.35)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[7] ? selectedColor : 'white'}

            />
            <path className="a" d="M564,408.32,533.4,488.16a401.86,401.86,0,0,1,64.34,24.27c16.34,7.84,31.75,17.62,48.34,26.93.5-5,1.32-9.5,1.33-14q.08-53.55.11-107.09Z" transform="translate(-98.22 -129.35)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[8] ? selectedColor : 'white'}
            />
            <path className="a" d="M134.29,357V452l62-87.66Z" transform="translate(-98.22 -129.35)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[9] ? selectedColor : 'white'}
            />
            <path className="a" d="M350.83,145.77q-17.55-.53-35.17-.47c-60.3.13-120.6,0-181.37,0v71.76l153.22,18.24Z" transform="translate(-98.22 -129.35)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[10] ? selectedColor : 'white'}
            />
            <path className="a" d="M408.37,249.72l-53.22-103.8-4.32-.15-63.32,89.55Z" transform="translate(-98.22 -129.35)"

            />
            <polyline className="c" points="28.95 332.75 255.07 12.95 433.99 361.92 542.49 79.13 544.46 73.45"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[11] ? selectedColor : 'white'}
            />
            <line className="d" x1="35.71" y1="227.57" x2="554.51" y2="289.57"
                stroke={selectedStroke}
            />
            <line className="e" x1="32.29" y1="87.28" x2="552.89" y2="149.28"
                stroke={selectedStroke}
            />
            <path d="M98.22,480.14V129.45c29.42,0,58.26-.27,87.1.06,56.62.66,113.25,1.16,169.85,2.64a815.47,815.47,0,0,1,212.48,33.4c27.2,8.11,53.67,18.71,80.47,28.17,7.41,2.61,11.68,6.55,11.65,15.69q-.51,177.55-.34,355.1c0,1.33-.34,2.66-.84,6.32-7.34-6-13.66-11.23-20.1-16.35C592,517.57,537.42,499.73,480.06,491.83c-48.31-6.66-97.31-9.85-146.1-11.06-73.47-1.82-147-.57-220.54-.63Zm36.07-334.82v319.9c9.57,0,18.09-.12,26.61,0,84.33,1.32,168.73,1,253,4.59,63.5,2.72,125.84,14.72,183.88,42.6,16.34,7.84,31.75,17.62,48.34,26.93.5-5,1.32-9.5,1.33-14q.23-153.15.42-306.29c0-8.5-4.08-12-11.26-14.4-32.63-10.73-64.79-23.08-97.85-32.23-72.88-20.17-147.57-27.34-223.06-27.17C255.36,145.43,195.06,145.32,134.29,145.32ZM122.17,466.23V144.52l-14,.92V466.23Z" transform="translate(-98.22 -129.35)"
                fill={selectedStroke}
            />
        </svg>
    )
}

export default Flag1


