import React from 'react'
import './styles/home.css';
import { Link } from "react-router-dom";

const GameHomepage = () => {
    return (
        <div id='games-box'>
            <div id='games-title'>
                <img src={process.env.PUBLIC_URL + 'images/controller.png'} className='game-icon'></img>
                <h2>Games</h2>
                <img src={process.env.PUBLIC_URL + 'images/dice.png'} className='game-icon'></img>
            </div>

            <div id='games-homepage'>
                <div id='games-container'>
                    <div className='game-card-homepage'>
                        <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/tic-tap-tone.jpg)` }} className='game-card-img'>
                        </div>
                        <div className='game-card-info'>
                            <h2>Tic Tap Tone</h2>
                            <p>Play our unique tic tac toe game with someone else in attendance while your waiting in between sets. Instead of x's and o's, we use trombones and drums.</p>
                            <Link style={{ textDecoration: 'none', width: '100%' }} to='/tic-tac-toe'><button className='play-game'>Play Game</button></Link>
                        </div>
                    </div>
                    <div className='game-card-homepage'>
                        <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/crossword.jpg)` }} className='game-card-img'>

                        </div>
                        <div className='game-card-info'>
                            <h2>Crossword Puzzle</h2>
                            <p>Dive into our crossword puzzle game designed to entertain you during the breaks between marching band sets. Explore musical clues and solve band-related mysteries.</p>
                            <Link style={{ textDecoration: 'none', width: '100%' }} to='/crossword'>
                                <button className='play-game'>Play Game</button>
                            </Link>

                        </div>
                    </div>
                    <div className='game-card-homepage'>
                        <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/trivia.jpg)`, backgroundColor: '#2a598f' }} className='game-card-img'>

                        </div>
                        <div className='game-card-info'>
                            <h2>Marching Band Trivia</h2>
                            <p>Immerse yourself in our marching band trivia game. Challenge your band knowledge, test your musical memory, and compete with friends or go solo.</p>
                            <Link style={{ textDecoration: 'none', width: '100%' }} to='/trivia'>
                                <button className='play-game'>Play Game</button>
                            </Link>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GameHomepage
