import React from 'react'
import ActivitiesHome from '../ActivitiesHome'
import Ad from '../Ad'

const ActivitiesPage = () => {
    return (
        <div>
            <ActivitiesHome />
            <Ad />
        </div>
    )
}

export default ActivitiesPage