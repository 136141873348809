
import React, { useState } from 'react'
import "./../styles/colorbook.css";



const Flag3 = ({ selectedColor, onPathClick, selectedStroke }) => {

    const [isClicked, setIsClicked] = useState(new Array(7).fill(false));

    const handleClick = (event, index) => {
        onPathClick(event);


        setIsClicked((prevIsClicked) =>
            prevIsClicked.map((clicked, i) => (i === index ? !clicked : clicked))
        );
    };





    return (


        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 561.55 441.48"
            className='svg-temp'
        >
            <defs>
                <style>
                    {
                        `
                         .f,.g{stroke:${selectedStroke}}.f,.g{stroke-miterlimit:10;}.b{stroke-width:5.28px;}
                        `
                    }

                </style>
            </defs>
            <path className="a" d="M94.67,440.44h15.2c73.52.06,147.07-1.18,220.54.64,48.79,1.2,97.79,4.4,146.1,11.05,57.36,7.9,112,25.74,158.43,62.65,6.44,5.12,12.76,10.38,20.1,16.35.5-3.65.84-5,.84-6.31q.11-177.56.34-355.11c0-9.14-4.24-13.07-11.65-15.69-26.8-9.46-53.27-20.06-80.47-28.17a815.46,815.46,0,0,0-212.47-33.4C295,91,238.39,90.47,181.77,89.82c-28.84-.34-57.68-.06-87.1-.06Z" transform="translate(-94.67 -89.65)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[0] ? selectedColor : 'black'}

            />
            <path className="b" d="M118.62,426.54h-14V105.74l14-.92Z" transform="translate(-94.67 -89.65)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[1] ? selectedColor : 'white'}

            />
            <path className="c" d="M312.11,105.6c-60.3.13-120.6,0-181.37,0V425.52L383.45,107.83C359.77,106.23,336,105.55,312.11,105.6Z" transform="translate(-94.67 -89.65)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[2] ? selectedColor : 'white'}

            />
            <path className="d" d="M448.8,433c50.13,5.39,99.13,17.52,145.39,39.73,16.34,7.85,31.75,17.62,48.35,26.94.49-5,1.31-9.51,1.32-14q.23-153.13.42-306.29a16.25,16.25,0,0,0-1.29-6.9Z" transform="translate(-94.67 -89.65)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[3] ? selectedColor : 'white'}


            />
            <path className="e" d="M633,165c-32.63-10.74-64.79-23.09-97.85-32.23-49.83-13.79-100.51-21.5-151.72-25L130.74,425.52c9.57,0,18.09-.11,26.61,0,84.33,1.32,168.73,1,253,4.6q19.33.83,38.49,2.86L643,172.5C641.26,168.72,637.84,166.59,633,165Z" transform="translate(-94.67 -89.65)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
                fill={isClicked[4] ? selectedColor : 'white'}

            />
            <line className="f" x1="290.55" y1="15.95" x2="36.06" y2="335.87"

            />

            <line className="g" x1="549.61" y1="81.12" x2="352.31" y2="345.8"

            />

            <path className="h" d="M309.36,411.06c-2.43-2.21-5.11-4.2-7.25-6.66-7.14-8.22-10.72-17.68-7.89-28.54,2.07-7.93,6.48-14.56,14-18.48,5.17-2.7,10.34-2,14.76,1.78s6.42,8.61,4.82,14.42c-1,3.59-3.56,6-6.71,7.83-3.5,2-4.65,3.94-4.13,7.24a8.64,8.64,0,0,0,6.35,6.68,5.47,5.47,0,0,0,4.38-1.23c3.74-2.54,6.06-6.27,8.18-10.08,3.08-5.52,5.9-11.18,8.79-16.81a14,14,0,0,0,.92-2.69,4.49,4.49,0,0,0-1.52-5.09c-4.27-3.69-8.52-7.4-12.57-11.31a60.36,60.36,0,0,1-15.7-26.4,68.86,68.86,0,0,1-.95-38.41A74,74,0,0,1,334.07,250,80.57,80.57,0,0,1,376,227c9.63-2,19.48-2.82,29.23-4.17,1-.13,1.89-.24,2.84-.3,5.44-.31,7.22-4.34,8.54-8.62,1.62-5.23,2.82-10.59,4.49-15.8a97.62,97.62,0,0,1,13.08-26,106.25,106.25,0,0,1,19.46-21.36c8.22-6.81,17.09-12.53,27.58-15.18,4.67-1.19,9.35-1.56,13.92.76a13.81,13.81,0,0,1,7.54,10.16,112.87,112.87,0,0,1,1.8,16.31,82.6,82.6,0,0,1-4,29.26,76.65,76.65,0,0,1-16,27.14A82.24,82.24,0,0,1,463,236.83c-6,3.33-12.52,5.11-19.13,6.69-4.74,1.14-9.54,2.31-13.44,5.4a21.39,21.39,0,0,0-5.24,5.75c-3.18,5.47-6,11.17-8.9,16.78-.5.94-.75,1.58.46,2.47,10.72,7.85,18.69,17.79,22.4,30.74,2.82,9.86,2.24,19.67-.8,29.43a53.64,53.64,0,0,1-7.22,15.27c-9.95,14.06-23.44,22.09-40.68,23.61a69.18,69.18,0,0,1-21.65-1.68,1.29,1.29,0,0,0-1.71.78c-3,5.43-6,10.91-9.21,16.22-4.18,6.88-8.28,13.83-13.59,19.94a23.86,23.86,0,0,1-25.95,7c-.58-.19-1.17-.31-1.76-.47Zm87.88-146.77,8.77-18a3,3,0,0,0-.55-.24c-14.72-1.2-28.83.74-41.78,8.34a58.37,58.37,0,0,0-20.95,21.27c-3.41,5.79-5,12.17-6,18.73-2.23,14.84,1,28.11,11.58,39.18a34.66,34.66,0,0,0,9.22,7.12L387.1,283.5c-13.67-4.55-23.55,1.53-32.55,11.36.23-2.11.32-3.78.58-5.42,1.78-10.84,7.5-18.61,17.89-22.66C380.77,263.76,388.82,263.43,397.24,264.29Zm38.67-41.56a40.42,40.42,0,0,0,12.19-1.9c13.12-4.05,22.16-12.93,28.61-24.65a81.72,81.72,0,0,0,9.51-33c.26-3.1.35-6.29-1.77-9.35a76.58,76.58,0,0,0-31.3,23.65C442.68,190.69,437.6,206,435.91,222.73ZM376.76,349.82a45.23,45.23,0,0,0,12.75,1.39C408,350.88,423,333,420.8,316.14c-1.3-10-6.4-17.41-14.66-23.05Z"
                transform="translate(-94.67 -89.65)"
                onClick={handleClick}
                style={{ pointerEvents: 'all' }}
            />
            <path d="M94.67,440.44V89.76c29.42,0,58.26-.28,87.1.06C238.39,90.47,295,91,351.63,92.45a815.46,815.46,0,0,1,212.47,33.4c27.2,8.11,53.67,18.71,80.47,28.17,7.41,2.62,11.68,6.55,11.65,15.69q-.5,177.55-.34,355.11c0,1.33-.34,2.66-.84,6.31-7.34-6-13.66-11.23-20.1-16.35C588.49,477.87,533.87,460,476.51,452.13c-48.31-6.65-97.31-9.85-146.1-11.05-73.47-1.82-147-.58-220.54-.64Zm36.07-334.81V425.52c9.57,0,18.09-.11,26.61,0,84.33,1.32,168.73,1,253,4.6,63.5,2.71,125.84,14.72,183.88,42.59,16.34,7.85,31.75,17.62,48.35,26.94.49-5,1.31-9.51,1.32-14q.23-153.13.42-306.29c0-8.49-4.08-12-11.26-14.39-32.63-10.74-64.79-23.09-97.85-32.23-72.88-20.17-147.57-27.34-223.06-27.18C251.81,105.73,191.51,105.63,130.74,105.63ZM118.62,426.54V104.82l-14,.92v320.8Z"
                transform="translate(-94.67 -89.65)"
                fill={selectedStroke}

            />
        </svg>
    );
};

export default Flag3



//square8