import React, { useEffect, useState } from 'react'
import './styles/home.css';

const IntroCarousel = () => {



    const [currentSlide, setCurrentSlide] = useState(0);

    const [activeSlideIcons, setActiveSlideIcons] = useState(Array(4).fill(false));


    const nextSlide = () => {
        const nextIndex = (currentSlide + 1) % 4;
        setCurrentSlide(nextIndex);
        updateActiveSlideIcons(nextIndex);
    };

    const prevSlide = () => {
        const prevIndex = currentSlide === 0 ? 4 - 1 : currentSlide - 1;
        setCurrentSlide(prevIndex);
        updateActiveSlideIcons(prevIndex);
    };

    const updateActiveSlideIcons = (activeIndex) => {
        const updatedIcons = activeSlideIcons.map((_, index) => index === activeIndex);
        setActiveSlideIcons(updatedIcons);
    };

    const handleIconClick = (index) => {
        setCurrentSlide(index);
    }

    useEffect(() => {
        const timer = setInterval(() => {
            nextSlide();
        }, 7000);

        return () => clearInterval(timer);
    });


    return (
        <div className='intro-carousel'>
            <div className='carousel-img-container'>
                <div className={`carousel-img-child ${currentSlide === 0 ? 'img-child-active' : ''}`} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/o-slide-1.jpeg)` }}>
                    <div className='intro-carousel-body'>
                        <h1>BETWEEN BANDS</h1>
                        <p className='slide-description'>Your Edutainment for Today's Marching Contest</p>
                    </div>
                </div>

                <div className={`carousel-img-child ${currentSlide === 1 ? 'img-child-active' : ''}`} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/o-slide-2.jpeg)` }}>
                    <div className='intro-carousel-body'>
                        <h1>Put the Popcorn Down</h1>
                        <p className='slide-description'>Learn About... Marching Band Contest Etiquette, Famous People Once In a Marching Band, Who These Band Members are, Marching Band History, and Much More</p>
                    </div>
                </div>

                <div className={`carousel-img-child ${currentSlide === 2 ? 'img-child-active' : ''}`} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/slide3.jpeg)` }}>
                    <div className='intro-carousel-body'>
                        <h1>Don't Miss a Beat</h1>
                        <p className='slide-description'>When you have some down time, play one of our fun games to entertain yourself, relax and enjoy our activities, or read one of our articles and learn something about marching band.</p>
                    </div>
                </div>

                <div className={`carousel-img-child ${currentSlide === 3 ? 'img-child-active' : ''}`} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/slide4.jpeg)` }}>
                    <div className='intro-carousel-body'>
                        <h1>Strike the Right Note</h1>
                        <p className='slide-description'>From Marching Band to Scholarship Dollars, Undergraduate Music Degrees, finding the right fit for college, And So Much More...</p>
                    </div>
                </div>




            </div>



            <div className='control-panel-intro-carousel'>
                <div onClick={prevSlide} className='prev-btn-intro-cr carousel-btn btn-background-circle'>
                    <svg className='arrow-prev-intro arrow-intro' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.53 162.9">
                        <path
                            className='prev-intro svg-btn-intro'
                            d="M217.4,165.87a7.76,7.76,0,0,0,0,12.12l52,41.61,38.68,30.94a12.87,12.87,0,0,0,20.91-10h0a12.88,12.88,0,0,0-4.83-10.05L310.62,219.6,251,171.93l59.6-47.65,13.57-10.85A12.89,12.89,0,0,0,329,103.38h0a12.87,12.87,0,0,0-20.91-10l-38.68,31Z"
                            transform="translate(-214.49 -90.49)"
                        />
                    </svg>
                </div>
                <div className='active-slide-icons'>
                    <div
                        className={`slide-icon ${currentSlide === 0 ? 'active-sld-icon' : ''}`}
                        onClick={() => setCurrentSlide(0)}
                    />
                    <div
                        className={`slide-icon ${currentSlide === 1 ? 'active-sld-icon' : ''}`}
                        onClick={() => setCurrentSlide(1)}
                    />
                    <div
                        className={`slide-icon ${currentSlide === 2 ? 'active-sld-icon' : ''}`}
                        onClick={() => setCurrentSlide(2)}
                    />
                    <div
                        className={`slide-icon ${currentSlide === 3 ? 'active-sld-icon' : ''}`}
                        onClick={() => setCurrentSlide(3)}
                    />
                </div>
                <div onClick={nextSlide} className='next-btn-intro-cr carousel-btn btn-background-circle'>
                    <svg className='arrow-next-intro arrow-intro' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114.53 162.9">
                        <path
                            className='next-intro svg-btn-intro'
                            d="M326.1,165.87a7.75,7.75,0,0,1,0,12.12l-52,41.61-38.69,30.94a12.86,12.86,0,0,1-20.9-10h0a12.88,12.88,0,0,1,4.83-10.05l13.57-10.85,59.6-47.67-59.6-47.65-13.57-10.85a12.89,12.89,0,0,1-4.83-10.05h0a12.86,12.86,0,0,1,20.9-10l38.69,31Z"
                            transform="translate(-214.49 -90.49)"
                        />
                    </svg>
                </div>
            </div>
        </div>
    )
}


export default IntroCarousel
