import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

const MobileHeader = () => {




    const [show, showNav] = useState(false);



    const currentLocation = useLocation();


    const closeMobileMenu = () => {
        showNav(false);
    };


    const toggleShowNav = () => {
        showNav(!show);
    }

    useEffect(() => {
        // Close the mobile menu when navigating to a different route
        showNav(false);

    }, [currentLocation]);

    return (
        <div>
            <div id='mobile-header'>
                <img src={process.env.PUBLIC_URL + '/images/logo-pink.png'} width='50px'></img>
                <div id='hamburger-menu' onClick={toggleShowNav}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>


            </div>
            {!show ? (
                <></>
            ) : (
                <div id='mobileNav'>
                    <div className='x-and-logo'>
                        <img src={process.env.PUBLIC_URL + '/images/logo-white.png'} width='30px'></img>
                        <div onClick={toggleShowNav} className='nav-x-btn-container'>
                            <div className='nav-x-bar'></div>
                            <div className='nav-x-bar'></div>
                        </div>
                    </div>
                    <nav className='mob-nav'>
                        <div className='nav-links-mobile'>


                            <Link style={{ textDecoration: 'none' }} to='/' className='link-mobile'>
                                <div className={`nav-link-mobile-wicon ${currentLocation.pathname === '/' ? 'nav-link-mobile-active' : ''}`}>
                                    <img className="tab-icon" src={process.env.PUBLIC_URL + '/images/home-icon-red.png'} ></img>
                                    <p>Home</p>
                                </div>
                            </Link>

                            <Link style={{ textDecoration: 'none' }} to='/about' className='link-mobile'>
                                <div className={`nav-link-mobile-wicon ${currentLocation.pathname === '/about' ? 'nav-link-mobile-active' : ''}`}>
                                    <img className="tab-icon" src={process.env.PUBLIC_URL + '/images/about-icon-red.png'} ></img>
                                    <p>About</p>
                                </div>
                            </Link>

                            <Link style={{ textDecoration: 'none' }} to='/articlespage' className='link-mobile'>
                                <div className={`nav-link-mobile-wicon ${currentLocation.pathname === '/articlespage' ? 'nav-link-mobile-active' : ''}`}>
                                    <img className="tab-icon" src={process.env.PUBLIC_URL + '/images/article-icon-red.png'} ></img>
                                    <p>Articles</p>
                                </div>
                            </Link>

                            <Link style={{ textDecoration: 'none' }} to='/games' className='link-mobile'>
                                <div className={`nav-link-mobile-wicon ${currentLocation.pathname === '/games' ? 'nav-link-mobile-active' : ''}`}>
                                    <img className="tab-icon" src={process.env.PUBLIC_URL + '/images/controller-yellow.png'} ></img>
                                    <p>Games</p>
                                </div>
                            </Link>

                            <Link style={{ textDecoration: 'none' }} to='/activitiespage' className='link-mobile'>
                                <div className={`nav-link-mobile-wicon ${currentLocation.pathname === '/activitiespage' ? 'nav-link-mobile-active' : ''}`}>
                                    <img className="tab-icon" src={process.env.PUBLIC_URL + '/images/activities-icon-red.png'} ></img>
                                    <p>Activities</p>
                                </div>
                            </Link>


                        </div>
                    </nav>
                </div >
            )}

        </div >
    )
}

export default MobileHeader
